/**
 * An epic to request update the issue data and emit actions to update the redux state.
 */
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { empty, from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CommentUpdate } from 'src/Utilities/types';

import epicRequest from 'mahso-common/services/utilities/epicRequest';

import { IssueApi } from '../../api';
import { appInsights } from '../../Utilities/AppInsights';
import {
    updateComment as updateCommentAction,
    updateCommentDone,
    updateCommentError,
    updateCommentStart
} from '../Issues/issuesSlice';

const updateComment = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(updateCommentAction),
        epicRequest({
            payloadReducer: (action: PayloadAction) => [action.payload],
            request: ({ payload }: PayloadAction<CommentUpdate>) =>
                from(
                    IssueApi.updateComment(
                        payload.id,
                        payload.comment.id,
                        payload
                    )
                ).pipe(
                    switchMap(() => empty()),
                    catchError((error) => {
                        appInsights.trackException({ error: new Error(error) });
                        throw error;
                    })
                ),
            actions: {
                start: updateCommentStart,
                done: updateCommentDone,
                fail: updateCommentError
            }
        })
    );

export default updateComment;
