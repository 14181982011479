import './IssuesModal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import Form from '@rjsf/bootstrap-4';
import _ from 'lodash';
import { AsyncState } from 'mahso-common/services/constants';
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { AjvError, ObjectFieldTemplateProps } from 'react-jsonschema-form';
import { useHistory, useParams } from 'react-router-dom';

import { reactPlugin } from '../../Utilities/AppInsights';
import { IssueState } from '../../Utilities/constants';
import { IssueObject, IssueUpdate, JsonData, State } from '../../Utilities/types';
import {
    selectFormAnswer,
    selectFormAnswerId,
    selectFormSchema,
    selectFormUiSchema,
    selectIssue,
    selectIssueLoadingStatus
} from './issuesSelector';
import { saveIssue } from './issuesSlice';

interface IssueTable {
    name: string;
    content: string;
}

/**
 *
 * @returns
 */
const IssueReviewModal = (): JSX.Element => {
    const history = useHistory();
    const { issueID } = useParams<{ issueID: string }>();
    const dispatch = useDispatch();
    const [loadingStatus, setLoading] = useState<AsyncState>();
    const [saveClicked, setSaveClicked] = useState(false);
    const appInsights = useAppInsightsContext();

    const handleClose = useCallback(() => {
        // Fixes a bug regarding that if the user selects a filter,
        // opens a review modal, then tries to close it.
        history.replace('/');
        history.goForward();
    }, [history]);

    const issueData = useSelector((state: State) => selectIssue(state, issueID));

    const formSchema = useSelector((state: State) => selectFormSchema(state, issueID));

    const formAnswerId = useSelector((state: State) => selectFormAnswerId(state, issueID));

    const dataSchema = useSelector((state: State) => selectFormAnswer(state, issueID));

    const uiSchema = useSelector((state: State) => selectFormUiSchema(state, issueID));

    /**
     * Get Issues loading status
     */
    const postStatus = useSelector((state: State) => selectIssueLoadingStatus(state, issueID));

    useEffect(() => {
        if (saveClicked && postStatus !== undefined) {
            setLoading(postStatus);
        }
    }, [postStatus, saveClicked]);

    // Customizes the layout of the fields in the form
    function ObjectFieldTemplate({ TitleField, properties, title }): JSX.Element {
        return (
            <Container fluid>
                <TitleField title={title} required id={title} />
                <Row>
                    {properties
                        ?.filter(
                            (prop) => prop.name === 'stationNumber' || prop.name === 'parentStation'
                        )
                        .map((prop) => (
                            <Col key={prop.name}>{prop.content}</Col>
                        ))}
                </Row>

                {properties
                    ?.filter(
                        // There are over 11 fields in the form that need to be formatted.
                        // The base number allowed is 11
                        // eslint-disable-next-line complexity
                        (prop) =>
                            prop.name !== 'stationNumber' &&
                            prop.name !== 'parentStation' &&
                            prop.name !== 'classification' &&
                            prop.name !== 'complexity' &&
                            prop.name !== 'visn' &&
                            prop.name !== 'market' &&
                            prop.name !== 'submarket' &&
                            prop.name !== 'sector' &&
                            prop.name !== 'visnObj' &&
                            prop.name !== 'marketEmpty' &&
                            prop.name !== 'submarketEmpty' &&
                            prop.name !== 'sectorEmpty'
                    )
                    .map((prop) => (
                        <Row key={prop.name}>
                            <Col>{prop.content}</Col>
                        </Row>
                    ))}
                <Row>
                    {properties
                        ?.filter(
                            (prop) => prop.name === 'classification' || prop.name === 'complexity'
                        )
                        .map((prop) => (
                            <Col key={prop.name}>{prop.content}</Col>
                        ))}
                </Row>
                {
                    // when the visn data is entered and the form re-renders the market
                    // is put onto a separate row and same for the other fields
                    // the market property doesn't exist until the visn data is entered
                }
                <Row id="visnField">
                    {properties
                        ?.filter(
                            (prop) =>
                                prop.name === 'visnObj' ||
                                prop.name === 'visn' ||
                                prop.name === 'market' ||
                                prop.name === 'submarket' ||
                                prop.name === 'sector'
                        )
                        .map((prop) => (
                            <Col key={prop.name}>{prop.content}</Col>
                        ))}

                    {properties
                        ?.filter(
                            (prop) =>
                                prop.name === 'marketEmpty' ||
                                prop.name === 'submarketEmpty' ||
                                prop.name === 'sectorEmpty'
                        )
                        .map((prop) => (
                            <Row key={prop.name}>
                                <Col>{prop.content}</Col>
                            </Row>
                        ))}
                </Row>
            </Container>
        );
    }

    // Transform Errors handles changing the default error messages
    // If any special characters are added to the database this code needs to change
    function transformErrors(errors: AjvError[]) {
        // Hides the following errors
        const hideErrors = [
            'should be equal to one of the allowed values',
            'should match exactly one schema in oneOf',
            'should be string'
        ];

        return errors
            .filter((error) => !hideErrors.includes(error.message))
            .map((e) => {
                if (e.name === 'pattern') {
                    // this is for form schema version 1
                    if (e.property === '.latitude' || e.property === '.longitude') {
                        e.message = `${_.startCase(
                            e.property.substring(1)
                        )} should only have valid decimals and negative numbers`;
                    } else if (e.property === '.stationNumber') {
                        e.message = `${_.startCase(
                            e.property.substring(1)
                        )} should only have letters and numbers`;
                    }
                    // If any special characters are added to the database this code needs to change
                    else if (
                        e.property === '.officialStationName' ||
                        e.property === '.locationDescriptiveName'
                    ) {
                        e.message = `${_.startCase(
                            e.property.substring(1)
                        )} can only have letters numbers and these special characters (.'-"(),)`;
                    }
                }
                if (e.name === 'maxLength') {
                    e.message = `${_.startCase(
                        e.property.substring(1)
                    )} should not be longer than ${(e.params?.limit as string) || ''}`;
                }
                return e;
            });
    }

    // Start FormFields
    /**
     * The form fields to render
     * Certain states/hooks are here to prevent rerendering
     * @param param0
     */
    const FormFields = (): JSX.Element => {
        const [formData, setFormData] = useState<JsonData>(dataSchema);
        const [visn, setVisn] = useState<string>(dataSchema.visnObj.visn);
        const [market, setMarket] = useState<string>(dataSchema.visnObj.market);
        const [subMarket, setSubmarket] = useState<string>(dataSchema.visnObj.submarket);
        const [state, setState] = useState<string>(dataSchema.stateObj.state);
        const [hierachyButtonStatus, setHierachyButtonStatus] = useState<boolean>(false);
        const [investigateButtonStatus, setInvestigateButtonStatus] = useState<boolean>(false);

        /**
         * Handles the save functionality
         * @param event
         */
        // eslint-disable-next-line complexity
        const handleSave = (event) => {
            event?.preventDefault();
            event?.stopPropagation();

            const formattedFormAnswers: JsonData = {
                stationNumber: formData.stationNumber ? formData.stationNumber : '',
                parentStation: formData.parentStation ? formData.parentStation : '',
                officialStationName: formData.officialStationName
                    ? formData.officialStationName
                    : '',
                locationDescriptiveName: formData.locationDescriptiveName
                    ? formData.locationDescriptiveName
                    : '',
                classification: formData.classification ? formData.classification : '',
                complexity: formData.complexity ? formData.complexity : '',
                congressionalDistrict: formData.congressionalDistrict
                    ? formData.congressionalDistrict
                    : '',
                visnObj: {
                    visn: formData.visnObj.visn ? formData.visnObj.visn : '',
                    market: formData.visnObj.market ? formData.visnObj.market : '',
                    submarket: formData.visnObj.submarket ? formData.visnObj.submarket : '',
                    sector: formData.visnObj.sector ? formData.visnObj.sector : ''
                },
                stateObj: {
                    state: formData.stateObj.state ? formData.stateObj.state : '',
                    county: formData.stateObj.county ? formData.stateObj.county : ''
                },
                latitude: formData.latitude ? formData.latitude : '',
                longitude: formData.longitude ? formData.longitude : '',
                isColocated: formData.isColocated ? formData.isColocated : ''
            };
            const payload: IssueUpdate = {
                id: Number(issueID),
                status: {
                    name:
                        (event.target as Element).id === 'hierarchy'
                            ? IssueState.SAVE_TO_HIERARCHY
                            : IssueState.UNDER_INVESTIGATION
                },
                formAnswers: {
                    id: formAnswerId,
                    answer: JSON.stringify({
                        JsonData: formattedFormAnswers,
                        UiSchema: uiSchema
                    })
                }
            };
            dispatch(saveIssue(payload));
            if (loadingStatus !== AsyncState.ERROR) {
                setTimeout(() => {
                    handleClose();
                }, 5000);
            }
            setSaveClicked(true);
        };
        // let saveToHierarchyButton;
        // function to check if the issueData has any null values
        function issueDataHasNull(issueObj: IssueObject): boolean {
            const parsedAnswers = issueObj;
            Object.keys(parsedAnswers).forEach((key: string) => {
                if (typeof parsedAnswers[key] === 'object') {
                    if (_.size(parsedAnswers[key]) > 0) {
                        Object.keys(parsedAnswers[key]).forEach((k: string) => {
                            if (
                                parsedAnswers[key][k] === null ||
                                parsedAnswers[key][k] === undefined ||
                                parsedAnswers[key][k] === ''
                            ) {
                                return false;
                            }
                            return true;
                        });
                    } else {
                        return false;
                    }
                } else if (
                    parsedAnswers[key] === null ||
                    parsedAnswers[key] === undefined ||
                    parsedAnswers[key] === ''
                ) {
                    return false;
                }
                return true;
            });
            return true;
        }
        useEffect(() => {
            // if issueData has a null value hide the save to Hierarchy button
            if (!issueDataHasNull(formData)) {
                setHierachyButtonStatus(true);
            }
        }, [formData]);

        /**
         * Handles any changes the user does to the form
         */
        const handleChange = useCallback(
            (form) => {
                // Reset form values in case user selects another option
                // This is because the old values are still being saved
                // Ex. If the user fills out everything and reselects Market (2nd from the list)
                //      Submarket/Sector's old values are still saved
                // This resolves that problem
                // Visn Object section
                if (form.formData.visnObj.visn !== visn) {
                    setVisn(form.formData.visnObj.visn);
                    setMarket('');
                    form.formData.visnObj.market = undefined;
                }
                if (form.formData.visnObj.market !== market) {
                    setMarket(form.formData.visnObj.market);
                    setSubmarket('');
                    form.formData.visnObj.submarket = undefined;
                }
                if (form.formData.visnObj.submarket !== subMarket) {
                    setSubmarket(form.formData.visnObj.submarket);

                    form.formData.visnObj.sector = undefined;
                }
                // State Object section
                if (form.formData.stateObj.state !== state) {
                    setState(form.formData.stateObj.state);
                    form.formData.stateObj.county = undefined;
                }

                // If any of the data fields are blank/undefined, that means the form is not filled out
                // However if they're all not blank, the form is filled out, display the Submit button
                // If there are errors disable the save to hierarchy button
                if (
                    form.errors.length > 0 ||
                    Object.values(form.formData).some(
                        (dataField) =>
                            dataField === undefined || dataField === null || dataField === ''
                    ) ||
                    Object.values(form.formData.stateObj).some(
                        (dataField) =>
                            dataField === undefined || dataField === null || dataField === ''
                    ) ||
                    Object.values(form.formData.visnObj).some(
                        (dataField) =>
                            dataField === undefined || dataField === null || dataField === ''
                    )
                ) {
                    setHierachyButtonStatus(true);
                } else {
                    setHierachyButtonStatus(false);
                }
                // Checks the form for any errors and if the there are disable investigate
                // further button
                if (form.errors.length > 0) {
                    setInvestigateButtonStatus(true);
                } else if (form.errors.length === 0) {
                    setInvestigateButtonStatus(false);
                }
                setFormData(form.formData);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [hierachyButtonStatus, formData]
        );

        return (
            <Form
                schema={formSchema}
                uiSchema={uiSchema}
                onSubmit={handleSave}
                onChange={(e): void => handleChange(e)}
                ObjectFieldTemplate={ObjectFieldTemplate}
                formData={formData}
                liveValidate
                showErrorList={false}
                transformErrors={transformErrors}
            >
                <Button
                    className="float-right mr-2"
                    onClick={handleSave}
                    variant="success"
                    id="hierarchy"
                    disabled={hierachyButtonStatus}
                >
                    Save to Hierarchy
                </Button>
                <Button
                    className="float-right mr-2"
                    variant="primary"
                    onClick={handleSave}
                    id="investigate"
                    disabled={investigateButtonStatus}
                >
                    Investigate Further
                </Button>
                <Button className="float-right mr-2" onClick={handleClose} variant="secondary">
                    Close
                </Button>
            </Form>
        );
    };
    // End FormFields
    // Logic to decide on what gets shown in the modal
    // After clicking either button depending on the loading status
    let content;
    if (loadingStatus === AsyncState.SUCCESS) {
        content = <h4>Issue Saved Successfully</h4>;
    } else if (loadingStatus === AsyncState.PENDING) {
        content = (
            <div>
                <Spinner animation="border" role="status">
                </Spinner>
                <h4>Loading...</h4>
            </div>
        );
    } else if (loadingStatus === AsyncState.ERROR) {
        content = (
            <div>
                <FormFields />
                <h4 style={{ color: 'red' }}>
                    We experienced an error saving the issue, please contact the support team.
                </h4>
            </div>
        );
    } else {
        content = <FormFields />;
    }
    return (
        <div>
            <Modal show size="lg" onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {issueData.status.name} - {issueData.stationNumber}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{content}</Modal.Body>
                <Modal.Footer />
            </Modal>
        </div>
    );
};

export default withAITracking(reactPlugin, IssueReviewModal, 'IssueReviewModal');
