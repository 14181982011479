/**
 * FacilityHierarchyReview
 * This is the API for working with the Facility Hierarchy Review Application
 *
 * OpenAPI spec version: 1.0.0
 * Contact: jkerski@definitivelogic.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import axios, { AxiosResponse, AxiosStatic } from 'axios';

import RelativeURL from 'mahso-common/data/relativeURL';
import { Configuration } from 'mahso-common/services/authorization/configuration';

import { COMMON_API_PATH, FACILITY_HIERARCHY_API_PATH, IssueState } from '~/Utilities/constants';
import { FormAnswerUpdate } from '~/Utilities/types';

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|'
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<AxiosResponse> | Promise<Response>;
}

export type FetchAPIType = FetchAPI | AxiosStatic;

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration!: Configuration;

    constructor(
        configuration?: Configuration,
        protected basePath: string = FACILITY_HIERARCHY_API_PATH,
        protected fetch: FetchAPIType = axios
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: 'RequiredError';

    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * Comment related to an issue
 * @export
 * @interface Comment
 */
export interface Comment {
    actionId: number;
    /**
     *
     * @type {number}
     * @memberof Comment
     */
    id: number;
    comment: {
        /**
         *
         * @type {string}
         * @memberof Comment
         */
        comment: string;
        /**
         *
         * @type {Date}
         * @memberof Comment
         */
        created?: string; // Stub replace type string with Date when API is done
        /**
         *
         * @type {User}
         * @memberof Comment
         */
        createdBy?: User;
    };
}

/**
 * Event for a data analysis such as Enterprise Health Care Planning Model
 * @export
 * @interface Event
 */
export interface Event {
    /**
     *
     * @type {number}
     * @memberof Event
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Event
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof Event
     */
    isActive: boolean;
    /**
     *
     * @type {Array<Phase>}
     * @memberof Event
     */
    phases: Array<Phase>;
}

/**
 *
 * @export
 * @interface Issue
 */
export interface Issue {
    /**
     *
     * @type {number}
     * @memberof Issue
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Issue
     */
    stationNumber: string;
    /**
     *
     * @type {IssueType}
     * @memberof Issue
     */
    typeOfIssue: IssueType;
    /**
     *
     * @type {IssueStatus}
     * @memberof Issue
     */
    status: IssueStatus;
    /**
     * List of actions that current use can perform with this issue
     * @type {Array<IssueAction>}
     * @memberof Issue
     */
    actionsAvailable?: Array<IssueAction>;
    /**
     *
     * @type {Date}
     * @memberof Issue
     */
    lastModifiedBy?: Date; // Stub Replace string with Date when API is done
    /**
     *
     * @type {User}
     * @memberof Issue
     */
    lastModified?: User;
    /**
     * json formatted answers to related form.
     * @type {string}
     * @memberof Issue
     */
    formAnswers?: string;
    /**
     * json formatted answers to related form.
     * @type {string}
     * @memberof Issue
     */
    formSchema?: string;
    /**
     *
     * @type {Array<Comment>}
     * @memberof Issue
     */
    comments?: Array<Comment>;
    /**
     *
     * @type {string}
     * @memberof Issue
     */
    loadingStatus?: string;
}

/**
 * Specific action that can be taken on an Issue
 * @export
 * @interface IssueUpdate
 */
export interface IssueUpdate {
    /**
     * This is the internal Issue ID within the database
     * @type {number}
     * @memberof IssueUpdate
     */
    id: number;
    /**
     *
     * @type {IssueState}
     * @memberof IssueUpdate
     */
    status?: {
        name: IssueState;
    };
    /**
     *
     * @type {FormAnswerUpdate}
     * @memberof IssueUpdate
     */
    formAnswers?: FormAnswerUpdate;
}

/**
 * Specific action that can be taken on an Issue
 * @export
 * @interface IssueAction
 */
export interface IssueAction {
    /**
     *
     * @type {number}
     * @memberof IssueAction
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof IssueAction
     */
    name?: string;
    /**
     *
     * @type {IssueStatus}
     * @memberof IssueAction
     */
    nextStatusIfActedOn?: IssueStatus;
}

/**
 * Designation for the status of an issue such as Under Investigation
 * @export
 * @interface IssueStatus
 */
export interface IssueStatus {
    /**
     *
     * @type {number}
     * @memberof IssueStatus
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof IssueStatus
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof IssueStatus
     */
    description?: string;
}

/**
 * Designation for an issue such as Planned Activations Missing Data
 * @export
 * @interface IssueType
 */
export interface IssueType {
    /**
     *
     * @type {number}
     * @memberof IssueType
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof IssueType
     */
    description?: string;
}

/**
 * Phase within an Event for a data analysis such as Enterprise Health Care Planning Model
 * @export
 * @interface Phase
 */
export interface Phase {
    /**
     *
     * @type {number}
     * @memberof Phase
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Phase
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof Phase
     */
    isActive: boolean;
}

/**
 * Describes what process and event this Facility Hierarchy Version is associated with such as the Market Area Health Systems Optimization Project - Phase 3
 * @export
 * @interface PhaseInformation
 */
export interface PhaseInformation {
    /**
     *
     * @type {Event}
     * @memberof PhaseInformation
     */
    event?: Event;
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {string}
     * @memberof User
     */
    upn?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    inSystem?: boolean;
}

/**
 *
 * @export
 * @interface Version
 */
export interface Version {
    /**
     *
     * @type {number}
     * @memberof Version
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Version
     */
    versionBuildStatus: string;
    /**
     *
     * @type {PhaseInformation}
     * @memberof Version
     */
    phaseInformation: PhaseInformation;
    /**
     *
     * @type {VersionBuildStatus}
     * @memberof Version
     */
    typeOfIssue?: VersionBuildStatus;
    /**
     * List of actions that current use can perform with this version
     * @type {Array<VersionAction>}
     * @memberof Version
     */
    actionsAvailable: Array<VersionAction>;
    /**
     *
     * @type {Date}
     * @memberof Version
     */
    lastModifiedBy: string; // Original type is Date
    /**
     *
     * @type {User}
     * @memberof Version
     */
    lastModified: User;
    /**
     * json formatted answers to related form.
     * @type {string}
     * @memberof Version
     */
    formAnswers: string;
    /**
     * json formatted answers to related form.
     * @type {string}
     * @memberof Version
     */
    formSchema: string;
    /**
     * URL to export Facility Hiearchy File to CSV
     * @type {string}
     * @memberof Version
     */
    urlOfVersionFile: string;
}

/**
 * Specific action that can be taken on an Version
 * @export
 * @interface VersionAction
 */
export interface VersionAction {
    /**
     *
     * @type {number}
     * @memberof VersionAction
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof VersionAction
     */
    name?: string;
    /**
     *
     * @type {VersionBuildStatus}
     * @memberof VersionAction
     */
    nextStatusIfActedOn?: VersionBuildStatus;
}

/**
 * Designation for the status of the build for the Facility Hiearchy Version such as Queued for Processing
 * @export
 * @interface VersionBuildStatus
 */
export interface VersionBuildStatus {
    /**
     *
     * @type {number}
     * @memberof VersionBuildStatus
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof VersionBuildStatus
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof VersionBuildStatus
     */
    description?: string;
}

const fetchApi = (localVarFetchArgs: FetchArgs) => {
    return (fetch: FetchAPIType = axios, basePath: string = FACILITY_HIERARCHY_API_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.data;
                }
                throw response;
            }
        );
    };
};

/** UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return User
         * @summary Gets the current User
         * @param {object} User the current user
         */
        getUser(user: User, options: any = {}): FetchArgs {
            const localVarPath = `/user/GetUser`;
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('user_auth', ['write:user', 'read:user'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        }
    };
};
export const UserApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Returns the User
         * @summary Get the current user for conducting data analysis
         * @param {object} user the current user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(
            user: User,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<User> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(
                user,
                options
            );
            return fetchApi(localVarFetchArgs);
        }
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPIType,
    basePath?: string
) {
    return {
        /**
         * Returns User
         * @summary Get User for conducting a data analysis
         * @param {object} user The current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(user: User, options?: any) {
            return UserApiFp(configuration).getUser(user, options)(fetch, basePath);
        }
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Returns Users
     * @summary Get the user for conducting a data analysis
     * @param {object} user The current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(user: User, options?: any) {
        return UserApiFp(this.configuration).getUser(user, options)(this.fetch, this.basePath);
    }
}

/**
 * EventApi - fetch parameter creator
 * @export
 */
export const EventApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all events
         * @summary Get all events available for conducting a data analysis
         * @param {number} [offset] The number of events to skip before starting to collect the result set.
         * @param {number} [limit] The number of events to return.
         * @param {number} [skip] Skip the number of events.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getevents(offset?: number, limit?: number, skip?: number, options: any = {}): FetchArgs {
            const localVarPath = `/event`;
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['read:version'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            if (offset !== undefined) {
                localVarQueryParameter.append('offset', offset.toString());
            }

            if (limit !== undefined) {
                localVarQueryParameter.append('limit', limit.toString());
            }

            if (skip !== undefined) {
                localVarQueryParameter.append('skip', skip.toString());
            }

            if (options.query) {
                Object.keys(options.query).forEach((key) =>
                    localVarQueryParameter.append(key, options.query[key].toString())
                );
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Returns all events
         * @summary Get all events available for conducting a data analysis
         * @param {number} [offset] The number of events to skip before starting to collect the result set.
         * @param {number} [limit] The number of events to return.
         * @param {number} [skip] Skip the number of events.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getevents(
            offset?: number,
            limit?: number,
            skip?: number,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Array<Event>> {
            const localVarFetchArgs = EventApiFetchParamCreator(configuration).getevents(
                offset,
                limit,
                skip,
                options
            );
            return fetchApi(localVarFetchArgs);
        }
    };
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPIType,
    basePath?: string
) {
    return {
        /**
         * Returns all events
         * @summary Get all events available for conducting a data analysis
         * @param {number} [offset] The number of events to skip before starting to collect the result set.
         * @param {number} [limit] The number of events to return.
         * @param {number} [skip] Skip the number of events.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getevents(offset?: number, limit?: number, skip?: number, options?: any) {
            return EventApiFp(configuration).getevents(
                offset,
                limit,
                skip,
                options
            )(fetch, basePath);
        }
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * Returns all events
     * @summary Get all events available for conducting a data analysis
     * @param {number} [offset] The number of events to skip before starting to collect the result set.
     * @param {number} [limit] The number of events to return.
     * @param {number} [skip] Skip the number of events.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getevents(offset?: number, limit?: number, skip?: number, options?: any) {
        return EventApiFp(this.configuration).getevents(
            offset,
            limit,
            skip,
            options
        )(this.fetch, this.basePath);
    }
}

/**
 * IssueApi - fetch parameter creator
 * @export
 */
export const IssueApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Add Comment to Issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {Comment} body Comment object that needs to be added to issue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(objectId: number, body: Comment, options: any = {}): FetchArgs {
            
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling addComment.'
                );
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    'body',
                    'Required parameter body was null or undefined when calling addComment.'
                );
            }
            const localVarPath = `/issue/AddComment?objectId={objectId}`.replace(
                `{${'objectId'}}`,
                encodeURIComponent(String(objectId))
            );
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'POST', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['write:issue', 'read:issue'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
            localVarRequestOptions.data = body;
            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         *
         * @summary Add a new issue
         * @param {Issue} body Issue object that needs to be added to the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIssue(body: Issue, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    'body',
                    'Required parameter body was null or undefined when calling addIssue.'
                );
            }
            const localVarPath = './issues_data.json';
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'POST', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['write:issue', 'read:issue'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
            localVarRequestOptions.data = body;

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         * Returns specific issue
         * @summary Get specific issue
         * @param {number} objectId ID of issue to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssue(objectId: number, options: any = {}): FetchArgs {
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling getIssue.'
                );
            }
            const localVarPath = `/issue/{objectId}`.replace(
                `{${'objectId'}}`,
                encodeURIComponent(String(objectId))
            );
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['write:issue', 'read:issue'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         * Returns all issues
         * @summary Get all issues
         * @param {number} [offset] The number of issues to skip before starting to collect the result set.
         * @param {number} [limit] The number of issues to return.
         * @param {number} [skip] Skip the number of issues.
         * @param {boolean} [modifiedRecently] Returns issues modified in the last 5 minutes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getissues(
            offset?: number,
            limit?: number,
            skip?: number,
            modifiedRecently?: boolean,
            options: any = {}
        ): FetchArgs {
            const localVarPath = '/issue/GetIssues';
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['write:issue', 'read:issue'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            if (offset !== undefined) {
                localVarQueryParameter.append('offset', offset.toString());
            }

            if (limit !== undefined) {
                localVarQueryParameter.append('limit', limit.toString());
            }

            if (skip !== undefined) {
                localVarQueryParameter.append('skip', skip.toString());
            }

            if (modifiedRecently !== undefined) {
                localVarQueryParameter.append('modifiedRecently', modifiedRecently.toString());
            }

            if (options.query) {
                Object.keys(options.query).forEach((key) =>
                    localVarQueryParameter.append(key, options.query[key].toString())
                );
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         *
         * @summary Update Comment to Issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {number} commentId ID of comment that needs to be updated
         * @param {Comment} body Comment object that needs to be added to issue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(
            objectId: number,
            commentId: number,
            body: Comment,
            options: any = {}
        ): FetchArgs {
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling updateComment.'
                );
            }
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError(
                    'commentId',
                    'Required parameter commentId was null or undefined when calling updateComment.'
                );
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    'body',
                    'Required parameter body was null or undefined when calling updateComment.'
                );
            }
            const localVarPath = `/issue/UpdateComment?commentId={commentId}?objectId={objectId}`
                .replace(`{${'objectId'}}`, encodeURIComponent(String(objectId)))
                .replace(`{${'commentId'}}`, encodeURIComponent(String(commentId)));
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'PATCH', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['write:issue', 'read:issue'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
            localVarRequestOptions.data = body;

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         *
         * @summary Update an existing issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {IssueUpdate} body Issue object that needs to be updated within the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssue(objectId: number, body: IssueUpdate, options: any = {}): FetchArgs {
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling updateIssue.'
                );
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    'body',
                    'Required parameter body was null or undefined when calling updateIssue.'
                );
            }
            const localVarPath = `/issue/UpdateIssue?objectId={objectId}`.replace(
                `{${'objectId'}}`,
                encodeURIComponent(String(objectId))
            );
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'PATCH', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['write:issue', 'read:issue'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
            localVarRequestOptions.data = body;

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * IssueApi - functional programming interface
 * @export
 */
export const IssueApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Add Comment to Issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {Comment} body Comment object that needs to be added to issue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(
            objectId: number,
            body: Comment,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Comment> {
            const localVarFetchArgs = IssueApiFetchParamCreator(configuration).addComment(
                objectId,
                body,
                options
            );
            return fetchApi(localVarFetchArgs);
        },
        /**
         *
         * @summary Add a new issue
         * @param {Issue} body Issue object that needs to be added to the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIssue(
            body: Issue,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Issue> {
            const localVarFetchArgs = IssueApiFetchParamCreator(configuration).addIssue(
                body,
                options
            );
            return fetchApi(localVarFetchArgs);
        },
        /**
         * Returns specific issue
         * @summary Get specific issue
         * @param {number} objectId ID of issue to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssue(
            objectId: number,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Issue> {
            const localVarFetchArgs = IssueApiFetchParamCreator(configuration).getIssue(
                objectId,
                options
            );
            return fetchApi(localVarFetchArgs);
        },
        /**
         * Returns all issues
         * @summary Get all issues
         * @param {number} [offset] The number of issues to skip before starting to collect the result set.
         * @param {number} [limit] The number of issues to return.
         * @param {number} [skip] Skip the number of issues.
         * @param {boolean} [modifiedRecently] Returns issues modified in the last 5 minutes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getissues(
            offset?: number,
            limit?: number,
            skip?: number,
            modifiedRecently?: boolean,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Array<Issue>> {
            const localVarFetchArgs = IssueApiFetchParamCreator(configuration).getissues(
                offset,
                limit,
                skip,
                modifiedRecently,
                options
            );
            return fetchApi(localVarFetchArgs);
        },
        /**
         *
         * @summary Update Comment to Issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {number} commentId ID of comment that needs to be updated
         * @param {Comment} body Comment object that needs to be added to issue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(
            objectId: number,
            commentId: number,
            body: Comment,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IssueApiFetchParamCreator(configuration).updateComment(
                objectId,
                commentId,
                body,
                options
            );
            return fetchApi(localVarFetchArgs);
        },
        /**
         *
         * @summary Update an existing issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {IssueUpdate} body Issue object that needs to be updated within the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssue(
            objectId: number,
            body: IssueUpdate,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IssueApiFetchParamCreator(configuration).updateIssue(
                objectId,
                body,
                options
            );
            return fetchApi(localVarFetchArgs);
        }
    };
};

/**
 * IssueApi - factory interface
 * @export
 */
export const IssueApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPIType,
    basePath?: string
) {
    return {
        /**
         *
         * @summary Add Comment to Issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {Comment} body Comment object that needs to be added to issue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(objectId: number, body: Comment, options?: any) {
            return IssueApiFp(configuration).addComment(objectId, body, options)(fetch, basePath);
        },
        /**
         *
         * @summary Add a new issue
         * @param {Issue} body Issue object that needs to be added to the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIssue(body: Issue, options?: any) {
            return IssueApiFp(configuration).addIssue(body, options)(fetch, basePath);
        },
        /**
         * Returns specific issue
         * @summary Get specific issue
         * @param {number} objectId ID of issue to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIssue(objectId: number, options?: any) {
            return IssueApiFp(configuration).getIssue(objectId, options)(fetch, basePath);
        },
        /**
         * Returns all issues
         * @summary Get all issues
         * @param {number} [offset] The number of issues to skip before starting to collect the result set.
         * @param {number} [limit] The number of issues to return.
         * @param {number} [skip] Skip the number of issues.
         * @param {boolean} [modifiedRecently] Returns issues modified in the last 5 minutes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getissues(
            offset?: number,
            limit?: number,
            skip?: number,
            modifiedRecently?: boolean,
            options?: any
        ) {
            return IssueApiFp(configuration).getissues(
                offset,
                limit,
                skip,
                modifiedRecently,
                options
            )(fetch, basePath);
        },
        /**
         *
         * @summary Update Comment to Issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {number} commentId ID of comment that needs to be updated
         * @param {Comment} body Comment object that needs to be added to issue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(objectId: number, commentId: number, body: Comment, options?: any) {
            return IssueApiFp(configuration).updateComment(
                objectId,
                commentId,
                body,
                options
            )(fetch, basePath);
        },
        /**
         *
         * @summary Update an existing issue
         * @param {number} objectId ID of issue that needs to be updated
         * @param {IssueUpdate} body Issue object that needs to be updated within the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIssue(objectId: number, body: IssueUpdate, options?: any) {
            return IssueApiFp(configuration).updateIssue(objectId, body, options)(fetch, basePath);
        }
    };
};

/**
 * IssueApi - object-oriented interface
 * @export
 * @class IssueApi
 * @extends {BaseAPI}
 */
export class IssueApi extends BaseAPI {
    /**
     *
     * @summary Add Comment to Issue
     * @param {number} objectId ID of issue that needs to be updated
     * @param {Comment} body Comment object that needs to be added to issue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public addComment(objectId: number, body: Comment, options?: any) {
        return IssueApiFp(this.configuration).addComment(
            objectId,
            body,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary Add a new issue
     * @param {Issue} body Issue object that needs to be added to the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public addIssue(body: Issue, options?: any) {
        return IssueApiFp(this.configuration).addIssue(body, options)(this.fetch, this.basePath);
    }

    /**
     * Returns specific issue
     * @summary Get specific issue
     * @param {number} objectId ID of issue to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public getIssue(objectId: number, options?: any) {
        return IssueApiFp(this.configuration).getIssue(objectId, options)(
            this.fetch,
            this.basePath
        );
    }

    /**
     * Returns all issues
     * @summary Get all issues
     * @param {number} [offset] The number of issues to skip before starting to collect the result set.
     * @param {number} [limit] The number of issues to return.
     * @param {number} [skip] Skip the number of issues.
     * @param {boolean} [modifiedRecently] Returns issues modified in the last 5 minutes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public getissues(
        offset?: number,
        limit?: number,
        skip?: number,
        modifiedRecently?: boolean,
        options?: any
    ) {
        return IssueApiFp(this.configuration).getissues(
            offset,
            limit,
            skip,
            modifiedRecently,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary Update Comment to Issue
     * @param {number} objectId ID of issue that needs to be updated
     * @param {number} commentId ID of comment that needs to be updated
     * @param {Comment} body Comment object that needs to be added to issue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public updateComment(objectId: number, commentId: number, body: Comment, options?: any) {
        return IssueApiFp(this.configuration).updateComment(
            objectId,
            commentId,
            body,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary Update an existing issue
     * @param {number} objectId ID of issue that needs to be updated
     * @param {IssueUpdate} body Issue object that needs to be updated within the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IssueApi
     */
    public updateIssue(objectId: number, body: IssueUpdate, options?: any) {
        return IssueApiFp(this.configuration).updateIssue(
            objectId,
            body,
            options
        )(this.fetch, this.basePath);
    }
}

/**
 * VersionApi - fetch parameter creator
 * @export
 */
export const VersionApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Add a new version of the Facility Hierarchy
         * @param {Version} body Version object that needs to be added to the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVersion(body: Version, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    'body',
                    'Required parameter body was null or undefined when calling addVersion.'
                );
            }
            const localVarPath = './version_data.json'; // Stub replace with `/version`;
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'POST', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['read:version', 'write:version'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
            localVarRequestOptions.data = body;

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         *
         * @summary Retrieve file that contains the data for this version of the Facility Hiearchy
         * @param {number} objectId ID of version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFacilityHiearchyFile(objectId: number, options: any = {}): FetchArgs {
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling exportFacilityHiearchyFile.'
                );
            }
            const localVarPath =
                './testCSV.csv'; /* Stub code replace with `/version/{objectId}/file`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId))); */
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['read:version', 'write:version'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         * Returns specific Version
         * @summary Get specific version
         * @param {number} objectId ID of version to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(objectId: number, options: any = {}): FetchArgs {
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling getVersion.'
                );
            }
            const localVarPath =
                './version_data.json'; /* Stub code replace with `/version/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId))); */
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['read:version', 'write:version'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         * Returns all versions
         * @summary Get all versions of the Facility Hierarchy
         * @param {number} [offset] The number of versions to skip before starting to collect the result set.
         * @param {number} [limit] The number of versions to return.
         * @param {number} [skip] Skip the number of versions.
         * @param {boolean} [modifiedRecently] Returns versions modified in the last 5 minutes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getversions(
            offset?: number,
            limit?: number,
            skip?: number,
            modifiedRecently?: boolean,
            options: any = {}
        ): FetchArgs {
            const localVarPath = './version_data.json'; // Stub code replace with `/version`;
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'GET', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['read:version', 'write:version'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            if (offset !== undefined) {
                localVarQueryParameter.append('offset', offset.toString());
            }

            if (limit !== undefined) {
                localVarQueryParameter.append('limit', limit.toString());
            }

            if (skip !== undefined) {
                localVarQueryParameter.append('skip', skip.toString());
            }

            if (modifiedRecently !== undefined) {
                localVarQueryParameter.append('modifiedRecently', modifiedRecently.toString());
            }

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        },
        /**
         *
         * @summary Update an existing version
         * @param {number} objectId ID of version that needs to be updated
         * @param {Version} body Version object that needs to be updated within the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(objectId: number, body: Version, options: any = {}): FetchArgs {
            // verify required parameter 'objectId' is not null or undefined
            if (objectId === null || objectId === undefined) {
                throw new RequiredError(
                    'objectId',
                    'Required parameter objectId was null or undefined when calling updateVersion.'
                );
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError(
                    'body',
                    'Required parameter body was null or undefined when calling updateVersion.'
                );
            }
            const localVarPath =
                './version_data.json'; /* Stub code replace with `/version/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId))); */
            const localVarUrlObj = new RelativeURL(localVarPath);
            const localVarRequestOptions = { method: 'PATCH', ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = new URLSearchParams(localVarUrlObj.searchParams);

            // authentication issue_auth required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue =
                    typeof configuration.accessToken === 'function'
                        ? configuration.accessToken('issue_auth', ['read:version', 'write:version'])
                        : configuration.accessToken;
                localVarHeaderParameter.Authorization = `Bearer ${localVarAccessTokenValue}`;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.search = localVarQueryParameter.toString();
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // delete localVarUrlObj.search;
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
            localVarRequestOptions.data = body;

            return {
                url: localVarUrlObj.URL,
                options: localVarRequestOptions
            };
        }
    };
};

/**
 * VersionApi - functional programming interface
 * @export
 */
export const VersionApiFp = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Add a new version of the Facility Hierarchy
         * @param {Version} body Version object that needs to be added to the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVersion(
            body: Version,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Version> {
            const localVarFetchArgs = VersionApiFetchParamCreator(configuration).addVersion(
                body,
                options
            );
            return (
                fetch: FetchAPIType = axios,
                basePath: string = FACILITY_HIERARCHY_API_PATH
            ) => {
                return fetch(
                    basePath +
                        localVarFetchArgs.url /* stub code uncomment when API is finished,localVarFetchArgs.options */
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        // stub code start
                        return response.json().then((d) => {
                            d.push(body);
                            return d;
                        });
                        // stub code end inbetween replace with return response.json();
                    }
                    throw response;
                });
            };
        },
        /**
         *
         * @summary Retrieve file that contains the data for this version of the Facility Hiearchy
         * @param {number} objectId ID of version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFacilityHiearchyFile(
            objectId: number,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VersionApiFetchParamCreator(
                configuration
            ).exportFacilityHiearchyFile(objectId, options);
            return (
                fetch: FetchAPIType = axios,
                basePath: string = FACILITY_HIERARCHY_API_PATH
            ) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
                    (response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response;
                        }
                        throw response;
                    }
                );
            };
        },
        /**
         * Returns specific Version
         * @summary Get specific version
         * @param {number} objectId ID of version to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(
            objectId: number,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Version> {
            const localVarFetchArgs = VersionApiFetchParamCreator(configuration).getVersion(
                objectId,
                options
            );
            return (
                fetch: FetchAPIType = axios,
                basePath: string = FACILITY_HIERARCHY_API_PATH
            ) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then(
                    (response) => {
                        if (response.status >= 200 && response.status < 300) {
                            // Start stub code
                            return response.json().then((res) => {
                                return res.find((d) => d.id === objectId);
                            });
                            // End stub code replace with return response.json()
                        }
                        throw response;
                    }
                );
            };
        },
        /**
         * Returns all versions
         * @summary Get all versions of the Facility Hierarchy
         * @param {number} [offset] The number of versions to skip before starting to collect the result set.
         * @param {number} [limit] The number of versions to return.
         * @param {number} [skip] Skip the number of versions.
         * @param {boolean} [modifiedRecently] Returns versions modified in the last 5 minutes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getversions(
            offset?: number,
            limit?: number,
            skip?: number,
            modifiedRecently?: boolean,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Array<Version>> {
            const localVarFetchArgs = VersionApiFetchParamCreator(configuration).getversions(
                offset,
                limit,
                skip,
                modifiedRecently,
                options
            );
            return fetchApi(localVarFetchArgs);
        },
        /**
         *
         * @summary Update an existing version
         * @param {number} objectId ID of version that needs to be updated
         * @param {Version} body Version object that needs to be updated within the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(
            objectId: number,
            body: Version,
            options?: any
        ): (fetch?: FetchAPIType, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VersionApiFetchParamCreator(configuration).updateVersion(
                objectId,
                body,
                options
            );
            return (
                fetch: FetchAPIType = axios,
                basePath: string = FACILITY_HIERARCHY_API_PATH
            ) => {
                return fetch(
                    basePath +
                        localVarFetchArgs.url /* stub code uncomment when API is finished, localVarFetchArgs.options */
                ).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        // Stub code start
                        return response.json().then((res) => {
                            return res.map((d) => (d.id === objectId ? body : d));
                        });
                        // stub code end replace with return response;
                    }
                    throw response;
                });
            };
        }
    };
};

/**
 * VersionApi - factory interface
 * @export
 */
export const VersionApiFactory = function (
    configuration?: Configuration,
    fetch?: FetchAPIType,
    basePath?: string
) {
    return {
        /**
         *
         * @summary Add a new version of the Facility Hierarchy
         * @param {Version} body Version object that needs to be added to the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVersion(body: Version, options?: any) {
            return VersionApiFp(configuration).addVersion(body, options)(fetch, basePath);
        },
        /**
         *
         * @summary Retrieve file that contains the data for this version of the Facility Hiearchy
         * @param {number} objectId ID of version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportFacilityHiearchyFile(objectId: number, options?: any) {
            return VersionApiFp(configuration).exportFacilityHiearchyFile(objectId, options)(
                fetch,
                basePath
            );
        },
        /**
         * Returns specific Version
         * @summary Get specific version
         * @param {number} objectId ID of version to return
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(objectId: number, options?: any) {
            return VersionApiFp(configuration).getVersion(objectId, options)(fetch, basePath);
        },
        /**
         * Returns all versions
         * @summary Get all versions of the Facility Hierarchy
         * @param {number} [offset] The number of versions to skip before starting to collect the result set.
         * @param {number} [limit] The number of versions to return.
         * @param {number} [skip] Skip the number of versions.
         * @param {boolean} [modifiedRecently] Returns versions modified in the last 5 minutes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getversions(
            offset?: number,
            limit?: number,
            skip?: number,
            modifiedRecently?: boolean,
            options?: any
        ) {
            return VersionApiFp(configuration).getversions(
                offset,
                limit,
                skip,
                modifiedRecently,
                options
            )(fetch, basePath);
        },
        /**
         *
         * @summary Update an existing version
         * @param {number} objectId ID of version that needs to be updated
         * @param {Version} body Version object that needs to be updated within the database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVersion(objectId: number, body: Version, options?: any) {
            return VersionApiFp(configuration).updateVersion(
                objectId,
                body,
                options
            )(fetch, basePath);
        }
    };
};

/**
 * VersionApi - object-oriented interface
 * @export
 * @class VersionApi
 * @extends {BaseAPI}
 */
export class VersionApi extends BaseAPI {
    /**
     *
     * @summary Add a new version of the Facility Hierarchy
     * @param {Version} body Version object that needs to be added to the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public addVersion(body: Version, options?: any) {
        return VersionApiFp(this.configuration).addVersion(body, options)(
            this.fetch,
            this.basePath
        );
    }

    /**
     *
     * @summary Retrieve file that contains the data for this version of the Facility Hiearchy
     * @param {number} objectId ID of version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public exportFacilityHiearchyFile(objectId: number, options?: any) {
        return VersionApiFp(this.configuration).exportFacilityHiearchyFile(objectId, options)(
            this.fetch,
            this.basePath
        );
    }

    /**
     * Returns specific Version
     * @summary Get specific version
     * @param {number} objectId ID of version to return
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public getVersion(objectId: number, options?: any) {
        return VersionApiFp(this.configuration).getVersion(objectId, options)(
            this.fetch,
            this.basePath
        );
    }

    /**
     * Returns all versions
     * @summary Get all versions of the Facility Hierarchy
     * @param {number} [offset] The number of versions to skip before starting to collect the result set.
     * @param {number} [limit] The number of versions to return.
     * @param {number} [skip] Skip the number of versions.
     * @param {boolean} [modifiedRecently] Returns versions modified in the last 5 minutes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public getversions(
        offset?: number,
        limit?: number,
        skip?: number,
        modifiedRecently?: boolean,
        options?: any
    ) {
        return VersionApiFp(this.configuration).getversions(
            offset,
            limit,
            skip,
            modifiedRecently,
            options
        )(this.fetch, this.basePath);
    }

    /**
     *
     * @summary Update an existing version
     * @param {number} objectId ID of version that needs to be updated
     * @param {Version} body Version object that needs to be updated within the database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionApi
     */
    public updateVersion(objectId: number, body: Version, options?: any) {
        return VersionApiFp(this.configuration).updateVersion(
            objectId,
            body,
            options
        )(this.fetch, this.basePath);
    }
}
