import './App.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js';
import { AzureAD } from 'react-aad-msal';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import authProvider from 'mahso-common/services/authorization/authProvider';

import { initApp } from '~/Modules/app/actions';

import IssuesTable from './Modules/Issues/IssuesTable';
import Sidebar from './Modules/NavigationContainer/Sidebar';
import UnauthorizedUser from './Modules/UnauthorizedUserPage/UnauthorizedUser';
import { reactPlugin } from './Utilities/AppInsights';

function App() {
    const dispatch = useDispatch();
    const [authorizedUser, setAuthorizedUser] = useState(true);

    useEffect(() => {
        dispatch(initApp());
        // Change logic when api is done
        // setAuthorizedUser(inSystemUser);
    });

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Router>
                <AzureAD provider={authProvider} forceLogin>
                    {authorizedUser && <Sidebar />}
                    {authorizedUser && (
                        <QueryParamProvider ReactRouterRoute={Route}>
                            <IssuesTable />
                        </QueryParamProvider>
                    )}
                    {!authorizedUser && <UnauthorizedUser />}
                </AzureAD>
            </Router>
        </AppInsightsContext.Provider>
    );
}

export default withAITracking(reactPlugin, App, 'FacilityHierarchyReviewApp');
