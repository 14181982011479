/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// This eslint rule is in place due to the version functionality not being fully developed
// Change <any> to UpdateVersion type when implemented
/**
 * An epic to request save the issue data and emit actions to update the redux state.
 */
import { Action, PayloadAction } from '@reduxjs/toolkit';
import epicRequest from 'mahso-common/services/utilities/epicRequest';
import { ofType } from 'redux-observable';
import { empty, from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { VersionApi } from '../../api';
import { appInsights } from '../../Utilities/AppInsights';
import {
    saveVersion as saveVersionAction,
    saveVersionDone,
    saveVersionError,
    saveVersionStart
} from '../Version/versionSlice';

const saveVersion = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(saveVersionAction),
        epicRequest({
            payloadReducer: undefined,
            request: ({ payload }: PayloadAction<any>) =>
                from(VersionApi.updateVersion(payload.id, payload)).pipe(
                    switchMap(() => empty()),
                    catchError((error) => {
                        appInsights.trackException({ error: new Error(error) });
                        throw error;
                    })
                ),
            actions: {
                start: saveVersionStart,
                done: saveVersionDone,
                fail: saveVersionError
            }
        })
    );
export default saveVersion;
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
