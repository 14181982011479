/**
 *  An epic to request save the issue data and emit actions to update the redux state.
 */
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IssueUpdate } from 'src/Utilities/types';

import epicRequest from 'mahso-common/services/utilities/epicRequest';

import { IssueApi } from '../../api';
import { appInsights } from '../../Utilities/AppInsights';
import {
    loadIssues,
    saveIssue as saveIssueAction,
    saveIssueDone,
    saveIssueError,
    saveIssueStart
} from '../Issues/issuesSlice';

const saveIssue = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(saveIssueAction),
        epicRequest({
            payloadReducer: (action: PayloadAction) => [action.payload],
            request: ({ payload }: PayloadAction<IssueUpdate>) =>
                from(IssueApi.updateIssue(payload.id, payload)).pipe(
                    switchMap(() => of(loadIssues(false))),
                    catchError((error) => {
                        appInsights.trackException({ error: new Error(error) });
                        throw error;
                    })
                ),
            actions: {
                start: saveIssueStart,
                done: saveIssueDone,
                fail: saveIssueError
            }
        })
    );
export default saveIssue;
