import './Modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { JSONSchema6 } from 'json-schema';
import _ from 'lodash';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import { useHistory, useParams } from 'react-router-dom';

import { AsyncState } from 'mahso-common/services/constants';
import {
    selectFirstName,
    selectLastName,
    selectUPN,
    selectUsername
} from 'mahso-common/services/user/selectors';

import { reactPlugin } from '../../Utilities/AppInsights';
import { IssueState } from '../../Utilities/constants';
import { CommentUpdate, IssueUpdate } from '../../Utilities/types';
import { actionsAvailableFromIssue, selectIssueLoadingStatus } from './issuesSelector';
import { addComment as addCommentAction, saveIssue as saveIssueAction } from './issuesSlice';

interface RequiresRemediationModalProps {
    issueID: string;
}

const formatDate = (date: Date, separator: string = '-') => {
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const year = date.getUTCFullYear();

    return `${month}${separator}${day}${separator}${year}`;
};

/**
 * Create a unique error message for the comment modal for readabilty to the users.
 * @param errors Error Message
 */
const transformErrors = (errors) =>
    errors.map((error) => {
        if (error?.name === 'minLength' || error?.name === 'required') {
            error.message = 'Remediation must be at least 3 characters long.';
        }
        return error || {};
    });

/**
 * The markup to render to the DOM within this component.
 * @returns {JSX} JSX markup
 * modal show = dialog open
 */
const RequiresRemediationModal: React.FC<RequiresRemediationModalProps> = () => {
    const history = useHistory();
    const { issueID } = useParams<RequiresRemediationModalProps>();
    const dispatch = useDispatch();
    const [buttonStatus, setButtonStatus] = useState<boolean>(true);
    const [formData, setFormData] = useState<{ [key: string]: unknown }>();
    const [loadingStatus, setLoading] = useState<AsyncState>();
    const [saveClicked, setSaveClicked] = useState(false);
    const schema: JSONSchema6 = {
        title: '',
        description: '',
        type: 'object',
        required: ['remediationDetails'],
        properties: {
            remediationDetails: {
                type: 'string',
                title: 'Please provide details on what changes needed to be made to the reporting database.',
                minLength: 3
            }
        }
    };
    const uiSchema = {
        remediationDetails: {
            'ui:widget': 'textarea',
            'ui:options': {
                rows: 5
            }
        }
    };
    /**
     * Get current user's UPN
     */
    const currentUserUPN = useSelector(selectUPN);
    /**
     * Get current user's FirstName
     */
    const currentUserFirstName = useSelector(selectFirstName);
    /**
     * Get current user's LastName
     */
    const currentUserLastName = useSelector(selectLastName);
    /**
     * Get current user's Username
     */
    const currentUserUsername = useSelector(selectUsername);
    /**
     * Get Issues loading status
     */
    const postStatus = useSelector((state) => selectIssueLoadingStatus(state, issueID));

    const actionsAvailable = useSelector((state) => actionsAvailableFromIssue(state, issueID));

    useEffect(() => {
        if (saveClicked && postStatus !== undefined) {
            setLoading(postStatus);
        }
    }, [postStatus, saveClicked]);
    /**
     * Executed when Close button or "X" button are clicked.
     */
    const handleClose = useCallback(() => {
        history.replace('/');
        history.goForward();
    }, [history]);
    /**
     * Set created date to today
     */
    const created = new Date();

    /**
     * Creates the payload for the Issue portion
     * @returns {IssuePayload} Issue Payload
     */
    const issuePayload = (): IssueUpdate => ({
        id: Number(issueID),
        status: {
            name: IssueState.REQUIRES_REMEDIATION
        }
    });

    /**
     * Creates the payload for the Comment portion
     * @returns {CommentPayload} Comment Payload
     */
    const commentPayload = (event): CommentUpdate => ({
        id: Number(issueID),
        comment: {
            comment: event.formData.remediationDetails,
            created: formatDate(created),
            createdBy: {
                upn: currentUserUPN,
                username: currentUserUsername,
                firstName: currentUserFirstName,
                lastName: currentUserLastName,
                roles: []
            }
        },
        actionId: _.result(
            _.find(actionsAvailable, { name: IssueState.REQUIRES_REMEDIATION }),
            'nextStatusIfActedOn.id'
        )
    });

    /**
     * Executed when Save button is clicked.
     * @param {Object} event
     */
    const handleSave = (event): void => {
        dispatch(saveIssueAction(issuePayload()));
        dispatch(addCommentAction(commentPayload(event)));

        // handleClose();
        setTimeout(() => {
            handleClose();
        }, 5000);
        setSaveClicked(true);
    };
    /**
     * Handle Change in Text Box
     */
    const handleChange = useCallback(
        (form) => {
            if (
                form?.formData &&
                Object.prototype.hasOwnProperty.call(form.formData, 'remediationDetails') &&
                form.formData.remediationDetails
            ) {
                if (form.formData.remediationDetails.length >= 3) {
                    setButtonStatus(false);
                } else {
                    setButtonStatus(true);
                }
            }
            setFormData(form.formData);
        },
        [setButtonStatus, setFormData]
    );
    let content;
    if (loadingStatus === AsyncState.SUCCESS) {
        content = <h5>Issue Saved Successfully</h5>;
    } else if (loadingStatus === AsyncState.PENDING) {
        content = (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    } else if (loadingStatus === AsyncState.ERROR) {
        content = (
            <div>
                <span style={{ color: 'red' }}>
                    We experienced an error saving the issue, please contact the support team.
                </span>
                <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={handleSave}
                    onChange={handleChange}
                    formData={formData}
                >
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>{' '}
                    <Button type="submit" variant="primary" disabled={buttonStatus}>
                        Save
                    </Button>
                </Form>
            </div>
        );
    } else {
        content = (
            <Form
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={handleSave}
                onChange={handleChange}
                formData={formData}
                liveValidate
                transformErrors={transformErrors}
            >
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>{' '}
                <Button type="submit" variant="primary" disabled={buttonStatus}>
                    Save
                </Button>
            </Form>
        );
    }
    return (
        <div>
            <Modal show onHide={handleClose} centered dialogClassName="requiresRemediationModal">
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>Requires Remediation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{content}</Modal.Body>
                </Modal.Dialog>
            </Modal>
        </div>
    );
};

export default withAITracking(reactPlugin, RequiresRemediationModal, 'RequiresRemediationModal');
