import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncState } from 'mahso-common/services/constants';
import { UserInfo, UserState } from 'mahso-common/services/types';

const initialState: UserState = {
    loadingStatus: undefined,
    loadingError: undefined,
    userInfo: undefined
};

const userState = createSlice({
    name: 'User',
    initialState,
    reducers: {
        /**
         * Set the current user information stored in state
         *
         * @param draftState - a mutable copy of the current redux state
         * @param action - a setUserInfo redux action
         * @param action.payload - a UserInfo object to set as the current user in the state
         */
        setUser(draftState, { payload }: PayloadAction<UserInfo>) {
            draftState.userInfo = payload;
        },
        /**
         * Emit a loadUser action. Kicks off an epic
         */
        loadUser() {},
        /**
         * Updates the loading status to "Pending"
         *
         * @param draftState - a mutable copy of the current redux state
         */
        loadUserStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },
        /**
         * Updates the loading status to "Complete"
         *
         * @param draftState - a mutable copy of the current redux state
         */
        loadUserDone(draftState) {
            draftState.loadingStatus = AsyncState.COMPLETE;
        },
        /**
         * Updates the loading status to "Error"
         *
         * @param draftState - a mutable copy of the current redux state
         * @param action - a loadUserError redux action
         * @param action.payload - a description of the error that occurred
         */
        loadUserError(draftState, { payload }: PayloadAction<string>) {
            draftState.loadingStatus = AsyncState.ERROR;
            draftState.loadingError = payload;
        }
    }
});

export const { setUser, loadUser, loadUserStart, loadUserDone, loadUserError } = userState.actions;

export default userState.reducer;
