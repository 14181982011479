import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType, MsalAuthProvider } from 'react-aad-msal';

// Msal Configurations
const config: Configuration = {
    auth: {
        authority: `${process.env.REACT_APP_LOGIN_AUTHORITY}/${process.env.REACT_APP_TENANT_ID}`,
        clientId: process.env.REACT_APP_CLIENT_ID || '',
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
    scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`],
};

// Options
const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.protocol}//${window.location.host}/auth.html`
};

const authProvider = new MsalAuthProvider(config, authenticationParameters, options);

export default authProvider;
