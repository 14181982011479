import './Modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { JSONSchema6 } from 'json-schema';
import _ from 'lodash';
import { Button, Modal, Spinner } from 'react-bootstrap';

/// react-bootstrap-table2-toolkit has a known issue with webpack 5.
/// Issue is identified here: https://github.com/webpack/webpack/issues/12197
/// However, I have not been able to successfully get any solution working locally
/// For now I have commented out react-bootstrap-table2-toolkit.
/// Additional files using it are:
/// src\index.tsx
/// src\Modules\Issues\IssuesTable.tsx
/// src\Modules\Issues\IssuesTableButtons.tsx
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Form, { AjvError } from 'react-jsonschema-form';
import { useHistory, useParams } from 'react-router-dom';

import { AsyncState } from 'mahso-common/services/constants';
import {
    selectFirstName,
    selectLastName,
    selectUPN,
    selectUsername
} from 'mahso-common/services/user/selectors';

import { reactPlugin } from '../../Utilities/AppInsights';
import { CommentUpdate, State } from '../../Utilities/types';
import { actionsAvailableFromIssue, selectIssue, selectIssueLoadingStatus } from './issuesSelector';
import { addComment as addCommentAction } from './issuesSlice';

const formatDate = (date: Date, separator: string = '-') => {
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const year = date.getUTCFullYear();

    return `${month}${separator}${day}${separator}${year}`;
};

/**
 * Comments History Columns definition
 */
const columns = [
    {
        dataField: 'id',
        text: 'ID',
        hidden: true
    },
    {
        dataField: 'comment',
        text: 'Comments'
    },
    {
        dataField: 'created',
        text: 'Created On',
        /**
         * @param cell
         */
        formatter: (cell: Date): string => {
            let dateObj = cell;
            if (typeof cell !== 'object') {
                dateObj = new Date(cell);
            }
            return formatDate(dateObj, '/');
        }
    },
    {
        dataField: 'createdBy.lastName',
        text: 'Created By',
        /**
         * @param row
         * @param row.createdBy
         * @param row.createdBy.lastName
         * @param row.createdBy.firstName
         */
        formatter: (row: { createdBy: { lastName: string; firstName: string } }): string =>
            `${row.createdBy.lastName}, ${row.createdBy.firstName}`
    }
];

interface CommentModalProps {
    issueID: string;
}
/**
 *
 * @param from
 * @param to
 * @param size
 * @returns
 */
const customTotal = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Comments
    </span>
);

const options = {
    disablePageTitle: true,
    paginationSize: 3,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
        {
            text: '4',
            value: 4
        }
    ] // A numeric array is also available. the purpose of above example is custom the text
};

/**
 * Create a unique error message for the comment modal for readabilty to the users.
 *
 * @param errors Error Message
 */
const transformErrors = (errors: AjvError[]) =>
    errors.map((error) => {
        if (error.name === 'minLength' || error.name === 'required') {
            error.message = 'Comment must be at least 3 characters long.';
        }
        return error;
    });

/**
 * The markup to render to the DOM within this component.
 *
 * @returns JSX markup
 * modal show = dialog open
 */
const CommentModal: React.FC<CommentModalProps> = () => {
    // const { SearchBar } = Search;
    const history = useHistory();
    const { issueID } = useParams<CommentModalProps>();
    const dispatch = useDispatch();
    const [buttonStatus, setButtonStatus] = useState<boolean>(true);
    const [formData, setFormData] = useState<{ comments: string }>();
    const [loadingStatus, setLoading] = useState<AsyncState>();
    const [saveClicked, setSaveClicked] = useState(false);

    const schema: JSONSchema6 = {
        title: ``,
        type: 'object',
        required: ['comments'],
        properties: {
            comments: {
                type: 'string',
                title: 'Comments',
                minLength: 3
            }
        }
    };

    const uiSchema = {
        comments: {
            'ui:widget': 'textarea',
            'ui:options': {
                rows: 5
            }
        }
    };
    /**
     * Get Issue Data to populate history of comments
     */
    const issueData = useSelector((state: State) => selectIssue(state, issueID));
    /**
     * Get current user's UPN
     */
    const currentUserUPN = useSelector(selectUPN);
    /**
     * Get current user's FirstName
     */
    const currentUserFirstName = useSelector(selectFirstName);
    /**
     * Get current user's LastName
     */
    const currentUserLastName = useSelector(selectLastName);
    /**
     * Get current user's Username
     */
    const currentUserUsername = useSelector(selectUsername);
    /**
     * Get Issues loading status
     */
    const postStatus = useSelector((state: State) => selectIssueLoadingStatus(state, issueID));

    const actionsAvailable = useSelector((state: State) =>
        actionsAvailableFromIssue(state, issueID)
    );

    useEffect(() => {
        if (saveClicked && postStatus !== undefined) {
            setLoading(postStatus);
        }
    }, [postStatus, saveClicked]);

    /**
     * Executed when Close button or "X" button are clicked.
     */
    const handleClose = useCallback(() => {
        history.replace('/');
        history.goForward();
    }, [history]);

    /**
     * Executed when Save button is clicked.
     *
     * @param event
     */
    const handleSave = (event: { formData: { comments: string } }): void => {
        const created = new Date();
        const payload: CommentUpdate = {
            id: issueData.id,
            comment: {
                comment: event.formData.comments,
                created: formatDate(created),
                createdBy: {
                    upn: currentUserUPN,
                    username: currentUserUsername,
                    firstName: currentUserFirstName,
                    lastName: currentUserLastName,
                    roles: []
                }
            },
            actionId: _.result(
                _.find(actionsAvailable, { name: 'Comments' }),
                'nextStatusIfActedOn.id'
            )
        };
        dispatch(addCommentAction(payload));
        setSaveClicked(true);
        if (formData !== undefined) {
            formData.comments = '';
        }
    };
    /**
     * Handle Change in Text Box
     */
    const handleChange = useCallback(
        (form: {
            formData: {
                comments: string;
            };
        }) => {
            // code only touches objects with hard coded keys
            // eslint-disable-next-line no-prototype-builtins
            if (form.formData.hasOwnProperty('comments') && form.formData.comments) {
                if (form.formData.comments.length >= 3) {
                    setButtonStatus(false);
                } else {
                    setButtonStatus(true);
                }
            }
            setFormData(form.formData);
        },
        [setButtonStatus, setFormData]
    );
    return (
        <Modal show onHide={handleClose} centered dialogClassName="commentModal">
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>{`Comments for ${issueData.stationNumber}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingStatus === AsyncState.ERROR ? (
                        <h4 style={{ color: 'red' }}>
                            We experienced an error saving the comment, please contact the support
                            team.
                        </h4>
                    ) : null}
                    {loadingStatus === AsyncState.SUCCESS ? (
                        <h4>Comment Saved Successfully</h4>
                    ) : null}
                    {loadingStatus === AsyncState.PENDING ? (
                        <div>
                            <Spinner animation="border" role="status">
                            </Spinner>
                            <h4>Loading...</h4>
                        </div>
                    ) : (
                        <Form
                            schema={schema}
                            uiSchema={uiSchema}
                            onSubmit={handleSave}
                            onChange={handleChange}
                            formData={formData}
                            transformErrors={transformErrors}
                        >
                            <Button type="submit" variant="primary" disabled={buttonStatus}>
                                Save
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {/* <ToolkitProvider
                        keyField="id"
                        data={issueData.comments || []}
                        columns={columns}
                        search
                    >
                        {({ searchProps, baseProps }) => (
                            <div>
                                <SearchBar {...searchProps} />
                                <hr />
                                <BootstrapTable
                                    {...baseProps}
                                    pagination={paginationFactory(options)}
                                />
                            </div>
                        )}
                    </ToolkitProvider> */}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

export default withAITracking(reactPlugin, CommentModal, 'CommentModal');
