import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AsyncState } from 'mahso-common/services/constants';

import { initialVersionState as initialState } from '../../Utilities/types';

const versionSlice = createSlice({
    name: 'Version',
    initialState,
    reducers: {
        /**
         * Set Versions stored in state
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setVersions action
         * @param {string[]} action.payload - array of Versions
         */
        setVersions(draftState, { payload }) {
            draftState.items = _.keyBy(payload, 'id');
        },
        /**
         * Set Version stored in state
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setVersion action
         * @param {string} action.payload - Object of version
         */
        setVersion(draftState, { payload }) {
            draftState.items[payload.id] = payload;
        },
        /**
         * Set Version Filter Values stored in state
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setVersionFilterValue action
         * @param {string[]} action.payload - array of Version filter values
         */
        setVersionFilterValue(draftState, { payload }) {
            draftState.filters = payload;
        },
        /**
         * Emit a loadVersions action. Does not update the state, but is used to kick off the
         * loadVersions epic
         */
        loadVersions(draftState, { payload }) {},
        /**
         * Update the loading status for the Versions to Pending
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadVersionsStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },
        /**
         *  Update the loading status for the Versions to Success
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadVersionsDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },
        /**
         * Update the loading status for the Versions to ERROR
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadVersionsError(draftState, { payload }) {
            draftState.loadingStatus = AsyncState.ERROR;
        },
        /**
         * Save the Version stored in state
         */
        saveVersion(draftState, { payload }) {},
        /**
         * Update the loading status for the saveIssue to Pending
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        saveVersionStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },
        /**
         *  Update the loading status for the saveIssue to Success
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        saveVersionDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },
        /**
         * Update the loading status for the saveIssues to ERROR
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        saveVersionError(draftState, { payload }) {
            draftState.loadingStatus = AsyncState.ERROR;
        },
        /**
         * Export the Version stored in state
         */
        exportVersion() {},
        /**
         *
         */
        editingVersionStarted(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },
        /**
         *
         */
        editingVersionStopped(draftState) {
            draftState.loadingStatus = undefined;
        }
    }
});

export const {
    setVersions,
    setVersion,
    setVersionFilterValue,
    loadVersions,
    loadVersionsStart,
    loadVersionsDone,
    loadVersionsError,
    saveVersion,
    saveVersionStart,
    saveVersionDone,
    saveVersionError,
    exportVersion
} = versionSlice.actions;

export default versionSlice.reducer;
