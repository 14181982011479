import './Sidebar.css';

import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../images/thumbnail.png';

const Sidebar = (): JSX.Element => (
    <div className="wrapper">
        <nav id="sidebar">
            <div className="sidebar-header">
                <img id="thumbnail" src={logo} alt="MAHSO Logo" />
            </div>
            <ul className="list-unstyled components">
                <li className="active">
                    <NavLink to="/issues" className="nav-link">
                        Issues
                    </NavLink>
                </li>
                {/*
                <li className="active">
                    <NavLink to="/snapshots" className="nav-link">
                        Snapshots
                    </NavLink>
                </li>
                <li className="active">
                    <NavLink to="/changeLog" className="nav-link">
                        Change Log
                    </NavLink>
                </li>
                */}
                <li className="active">
                    <a
                        href="mailto:mahso-support@definitivelogic.com?subject=Facility Hierarchy Application Support Request"
                        style={{ textDecoration: 'none' }}
                    >
                        Contact
                    </a>
                </li>
            </ul>
        </nav>
    </div>
);

export default Sidebar;
