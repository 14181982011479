import './UnauthorizedUser.css';

import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { NavLink, Route } from 'react-router-dom';

import logo from '../../images/cl-va-logo-transparent.png';
import UnauthorizedUserAboutUsModal from './UnauthorizedUserAboutUsModal';
import UnauthUserContactUs from './UnauthUserContactUs';

const UnauthorizedUser = (): JSX.Element => (
    <Container fluid>
        <Route exact path="/AboutUs" component={UnauthorizedUserAboutUsModal} />
        <Route exact path="/ContactUs" component={UnauthUserContactUs} />
        <div id="page-header">
            <Row className="mstr-header-logo-row">
                <Col md="6">
                    <img className="img-responsive" role="complementary" src={logo} alt="VA Logo" />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <div className="breadcrumb i5breadcrumb">
                        <span className="va-nav-header-row">
                            VA Single Sign-on |
                            <NavLink
                                href="#"
                                to="/AboutUs"
                                id="breadcrumb-about-btn"
                                aria-label="about dialog"
                                role="button"
                                aria-labelledby="About"
                            >
                                {' '}
                                About
                            </NavLink>{' '}
                            |
                            <NavLink
                                href="#"
                                to="/ContactUs"
                                id="breadcrumb-cu-btn"
                                aria-label="contact us dialog"
                                role="button"
                                aria-labelledby="Contact Us"
                            >
                                {' '}
                                Contact Us
                            </NavLink>
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
        <div id="maincontent" tabIndex={-1}>
            <div id="ContentBody">
                <Row>
                    <Col md="12">
                        <div className="homeHeader corrected text-center">
                            <br /> VA Access Control Services
                        </div>
                        <div>
                            <div className="homeSectionHeader corrected text-center">
                                Error 403 - Forbidden
                                <br />
                                <br />
                                <p>If you need additional assistance,</p>
                                <Row>
                                    <Col md="12">
                                        <b>Email:</b>
                                        <a href="mailto:mahso-support@definitivelogic.com?subject=Facility Hierarchy Application Support Request">
                                            <b> mahso-support@definitivelogic.com</b>
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" className="mt-2">
                                        <b>Call:</b>{' '}
                                        <span>
                                            1-855-673-4357 (Option 3, then use Option 2 for sign in
                                            support).{' '}
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" className="mt-2">
                                        <p>
                                            <b>Online:</b>{' '}
                                            <a
                                                href="https://yourit.va.gov"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <b>https://yourit.va.gov </b>
                                            </a>
                                            for self-service support (Google Chrome web browser and
                                            VA Network access required)
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <p>&nbsp;</p>
            <Row className="mstr-legal-warning-row">
                <Col md="2">
                    <p className="mstr-warning" role="contentinfo">
                        WARNING:{' '}
                    </p>
                </Col>
                <Col md="10">
                    <p role="contentinfo" className="mstr-warning-body">
                        This U.S government system is intended to be used by [authorized VA network
                        users] for viewing and retrieving information only, except as otherwise
                        explicitly authorized. VA information resides on and transmits through
                        computer systems and networks funded by VA. All use is considered to be with
                        an understanding and acceptance that there is no reasonable expectation of
                        privacy for any data ir transmissions on Government Intranet or Extranet
                        (non-public) networks or systems. All transactions that occur on this system
                        are subject to review and action including (but not limited to) monitoring,
                        recording, retrieving, copying, auditing, inspecting, investigating,
                        restricting access, blocking, tracking, disclosing to authorized personnel,
                        or any other authorized actions by all authorized VA and law enforcement
                        personnel. All use of this system constitutes understanding and
                        unconditional acceptance of these terms.
                    </p>
                    <p role="contentinfo" className="mstr-warning-body">
                        Unauthorized attemps or acts to either (1) access, upload, change, or delete
                        information on this system, (2) modify this system, (3) deny access to this
                        system, or (4) accure resources for unauthorized use on this system are
                        strictly prohibited. Such attempts or acts are subject to action that may
                        result in criminal, civil, or administrative penalties.
                    </p>
                </Col>
            </Row>
            <p>&nbsp;</p>
        </div>
        <footer id="footer">
            <div className="page-footer mstr-footer-div">
                <Row className="template-footer-row1">
                    <Col md="12">
                        <ul className="template-link-list">
                            <li className="style-guide-footer-hyperlink">
                                <a
                                    className="style-guide-footer-hyperlink"
                                    href="http://www.va.gov"
                                >
                                    VA HOME
                                </a>
                            </li>
                            <li className="style-guide-footer-hyperlink">
                                <a
                                    className="style-guide-footer-hyperlink"
                                    href="http://www.va.gov/privacy"
                                >
                                    PRIVACY
                                </a>
                            </li>
                            <li className="style-guide-footer-hyperlink">
                                <a
                                    className="style-guide-footer-hyperlink"
                                    href="http://www.va.gov/foia"
                                >
                                    FOIA
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className="template-footer-row2">
                    <Col lg="12" className="template-footer-align">
                        <p className="style-guide-body-text-smallest-white">
                            U.S. Department of Veterans Affairs | 810 Vermont Avenue, NW Washington
                            DC 20420
                        </p>
                    </Col>
                </Row>
            </div>
        </footer>
    </Container>
);

export default UnauthorizedUser;
