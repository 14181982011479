import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { AsyncState } from 'mahso-common/services/constants';

import { Comment, initialIssuesState as initialState, IssueUpdate } from '../../Utilities/types';

const issuesSlice = createSlice({
    name: 'issuesSlice',
    initialState,
    reducers: {
        /**
         * Set the Issues stored in state
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setIssues action
         * @param {string[]} action.payload - array of issues
         */
        setIssues(draftState, { payload }) {
            draftState.items = { ...draftState.items, ..._.keyBy(payload, 'id') };
        },
        /**
         * Set the issue stored in state
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setIssue action
         * @param {string} action.payload - Object of issue
         */
        setIssue(draftState, { payload }) {
            draftState.items[payload.id] = payload;
        },
        /**
         * Set the filter Value stored in state
         *
         * @param {object} draftState - a mutable copy of the current redux state
         * @param {object} action - the setIssueFilterValue action
         * @param {string[]} action.payload - array of filterValue
         */
        setIssueFilterValue(draftState, { payload }) {
            draftState.filters = payload;
        },
        /**
         * Emit a loadIssues action. Does not update the state, but is used to kick off the
         * loadIssues epic
         */
        /* eslint-disable @typescript-eslint/no-empty-function */
        loadIssues(draftState, { payload }) { },
        /* eslint-enable @typescript-eslint/no-empty-function */
        /**
         * Update the loading status for the Issue to Pending
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadIssuesStart(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },
        /**
         *  Update the loading status for the Issue to Success
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadIssuesDone(draftState) {
            draftState.loadingStatus = AsyncState.SUCCESS;
        },
        /**
         * Update the loading status for the Issue to ERROR
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        loadIssuesError(draftState, { payload }) {
            draftState.loadingStatus = AsyncState.ERROR;
            draftState.loadingError = payload;
        },
        /**
         * Save the Issue stored in state
         */
        /* eslint-disable @typescript-eslint/no-empty-function */
        saveIssue(draftState, { payload }: PayloadAction<IssueUpdate>) { },
        /* eslint-enable @typescript-eslint/no-empty-function */
        /**
         * Update the loading status for the saveIssue to Pending
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        saveIssueStart(draftState, { payload }: PayloadAction<{ id: number }>) {
            draftState.items[payload.id].loadingStatus = AsyncState.PENDING;
        },
        /**
         *  Update the loading status for the saveIssue to Success
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        saveIssueDone(draftState, { payload }: PayloadAction<{ id: number }>) {
            draftState.items[payload.id].loadingStatus = AsyncState.SUCCESS;
        },
        /**
         * Update the loading status for the saveIssues to ERROR
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        saveIssueError(draftState, { payload }: PayloadAction<{ id: number }>) {
            draftState.items[payload.id].loadingStatus = AsyncState.ERROR;
        },
        /**
         * Update the loading status for editing Issue to EDITING
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        editingIssueStarted(draftState) {
            draftState.loadingStatus = AsyncState.PENDING;
        },
        /**
         * Update the loading status for editing Issue to NONE
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        editingIssueStopped(draftState) {
            draftState.loadingStatus = undefined;
        },
        /**
         * Add Comment to Issue
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        addComment(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment; actionId: number }>
        ) {
            draftState.items[payload.id].comments.push(payload.comment);
        },
        /**
         * Update the loading status for the addComment to Pending
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        addCommentStart(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment; actionId: number }>
        ) {
            draftState.items[payload.id].loadingStatus = AsyncState.PENDING;
        },
        /**
         *  Update the loading status for the addComment to Success
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        addCommentDone(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment; actionId: number }>
        ) {
            draftState.items[payload.id].loadingStatus = AsyncState.SUCCESS;
        },
        /**
         * Update the loading status for the addComment to ERROR
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        addCommentError(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment; actionId: number }>
        ) {
            draftState.items[payload.id].loadingStatus = AsyncState.ERROR;
        },
        /**
         * Edit CommentIssue
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        updateComment(draftState, { payload }: PayloadAction<{ id: number; comment: Comment }>) {
            // draftState.items[payload.id].comments._findIndex(payload.comment.id);
            // https://lodash.com/docs/#find
            draftState.items[payload.id].comments.push(payload.comment);
            _.find({ ...draftState.items[payload.id].comments }, (o) => {
                if (o.id === payload.comment.id) {
                    // Status: Found the matching ID and new comment
                    // TODO: After API Save Feature, come back
                    return o.id === payload.comment.id;
                }
                return false;
            });
        },
        /**
         * Update the loading status for the editComment to Pending
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        updateCommentStart(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment }>
        ) {
            draftState.items[payload.id].loadingStatus = AsyncState.PENDING;
        },
        /**
         *  Update the loading status for the editComment to Success
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        updateCommentDone(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment }>
        ) {
            draftState.items[payload.id].loadingStatus = AsyncState.SUCCESS;
        },
        /**
         * Update the loading status for the editComment to ERROR
         *
         * @param {object} draftState - a mutable copy of the current redux state
         */
        updateCommentError(
            draftState,
            { payload }: PayloadAction<{ id: number; comment: Comment }>
        ) {
            draftState.items[payload.id].loadingStatus = AsyncState.ERROR;
        }
    }
});

export const {
    setIssues,
    setIssue,
    setIssueFilterValue,
    loadIssues,
    loadIssuesStart,
    loadIssuesDone,
    loadIssuesError,
    saveIssue,
    saveIssueStart,
    saveIssueDone,
    saveIssueError,
    editingIssueStarted,
    editingIssueStopped,
    addComment,
    addCommentStart,
    addCommentDone,
    addCommentError,
    updateComment,
    updateCommentStart,
    updateCommentDone,
    updateCommentError
} = issuesSlice.actions;

export default issuesSlice.reducer;
