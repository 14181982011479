import './UnauthorizedUserModal.css';

import React, { useCallback, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import logo from '../../images/cl-va-logo-modal.png';
import closeImage from '../../images/cl-lightweight-modal-close-button.png';

const UnauthUserContactUs = (): JSX.Element => {
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.replace('/');
        history.goForward();
    }, [history]);

    return (
        <Modal show onHide={handleClose}>
            <Modal.Header className="modal-header-va-blue">
                <Modal.Title>
                    <img src={logo} alt="VA Logo Modal" />
                </Modal.Title>
                <button
                    id="about-modal-close-btn"
                    type="button"
                    aria-label="Close Dialog"
                    className="close"
                    onClick={handleClose}
                >
                    <img src={closeImage} alt="Close Dialog" />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="12">
                        <b>Email:</b>
                        <a href="mailto:mahso-support@definitivelogic.com?subject=Facility Hierarchy Application Support Request">
                            <b> mahso-support@definitivelogic.com</b>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="mt-2">
                        <b>Call:</b>{' '}
                        <span>
                            1-855-673-4357 (Option 3, then use Option 2 for sign in support).{' '}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="mt-2">
                        <p>
                            <b>Online:</b>{' '}
                            <a href="https://yourit.va.gov" target="_blank" rel="noreferrer">
                                <b>https://yourit.va.gov </b>
                            </a>
                            for self-service support (Google Chrome web browser and VA Network
                            access required)
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={handleClose}>
                    Back
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default UnauthUserContactUs;
