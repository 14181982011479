import { Action, PayloadAction } from '@reduxjs/toolkit';
import epicRequest from 'mahso-common/services/utilities/epicRequest';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { VersionApi } from '../../api';
import { appInsights } from '../../Utilities/AppInsights';
import {
    loadVersions as loadVersionsAction,
    loadVersionsDone,
    loadVersionsError,
    loadVersionsStart,
    setVersions
} from '../Version/versionSlice';

const loadVersions = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(loadVersionsAction),
        epicRequest({
            payloadReducer: undefined,
            request: ({ payload }: PayloadAction<boolean>) =>
                from(VersionApi.getversions(undefined, undefined, undefined, payload)).pipe(
                    switchMap((versions) => of(setVersions(versions))),
                    catchError((error) => {
                        appInsights.trackException({ error: new Error(error) });
                        throw error;
                    })
                ),
            actions: {
                start: loadVersionsStart,
                done: loadVersionsDone,
                fail: loadVersionsError
            }
        })
    );
export default loadVersions;
