import { Action, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import { rootEpic, rootReducer } from './root';

const epicMiddleware = createEpicMiddleware();
const middleware = getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware);

export const store = configureStore({
    reducer: rootReducer,
    middleware
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
