import { Action, ActionCreator } from '@reduxjs/toolkit';
import { concat, Observable, of } from 'rxjs';
/**
 * Observable operator that listens to redux actions and makes a request,
 * emitting request life cycle actions along the way.
 *
 * @author Brandan D. Reed
 * @author Anthony P. Pancerella
 */
import { catchError, mergeMap } from 'rxjs/operators';

type EpicRequestOptions = {
    payloadReducer?: (action: Action) => unknown[];
    request: (action: Action) => Observable<Action>;
    actions: {
        start: ActionCreator<Action>;
        done: ActionCreator<Action>;
        fail: ActionCreator<Action>;
    };
};

// TODO: Integrate with dl-core-js
/**
 * Create a standard asynchronous request epic.  The created epic will listen to a particular action
 * type and will emit a start action prior to making the request, a done action upon completion, and
 * a fail action on error.
 *
 * @param options - options to be passed into this operator
 * @param options.payloadReducer - this will convert the payload from the action into an array of
 * parameters that will be passed to the START/DONE/FAIL action creators
 * @param options.request - this will be called to make an asynchronous
 * request and should return an Observable that will emit any extra actions necessary
 * @param options.actions - the START/DONE/FAIL action creator functions
 * @returns a stream of actions, including the START/DONE/FAIL actions along with any
 * actions emitted by the responseHandler Observable
 */
const epicRequest = ({ payloadReducer, request, actions }: EpicRequestOptions) => (
    source: Observable<Action>
): Observable<Action> =>
    source.pipe(
        mergeMap((action) => {
            // Create the request parameters by calling payloadReducer on the action
            const requestParameters = payloadReducer ? payloadReducer(action) : [];
            // Concatenate the start, request and the done actions in order
            return concat(
                // Emit the start action
                of(actions.start(...requestParameters)),
                // Execute the request
                request(action),
                // Emit the done action
                of(actions.done(...requestParameters))
                // If an error occurs during the process, emit a failure action
            ).pipe(catchError((error) => of(actions.fail(...requestParameters, error))));
        })
    );

export default epicRequest;
