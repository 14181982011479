/**
 *  * An epic to request save the issue data and emit actions to update the redux state.
 */

import { Action, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { empty, from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CommentUpdate } from 'src/Utilities/types';

import epicRequest from 'mahso-common/services/utilities/epicRequest';

import { IssueApi } from '~/api';

// import { appInsights } from '../../Utilities/AppInsights';
import {
    addComment as addCommentAction,
    addCommentDone,
    addCommentError,
    addCommentStart
} from '../Issues/issuesSlice';

/**
 *
 * @param action$
 * @returns
 */
const addComment = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(addCommentAction),
        epicRequest({
            payloadReducer: (action: PayloadAction) => [action.payload],
            request: ({ payload }: PayloadAction<CommentUpdate>) =>
                from(IssueApi.addComment(payload.id, payload)).pipe(
                    switchMap(() => empty()),
                    catchError((error) => {
                        // appInsights.trackException({ error: new Error(error) });
                        throw error;
                    })
                ),
            actions: {
                start: addCommentStart,
                done: addCommentDone,
                fail: addCommentError
            }
        })
    );
export default addComment;
