import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    // Use environment variables for different builds
    config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        autoTrackPageVisitTime: true,
        disableFetchTracking: false,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableCorsCorrelation: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const telemetryInitializer = (envelope) => {
    envelope.tags['ai.cloud.role'] = 'Facility Hierarchy Review FrontEnd';
};
appInsights.addTelemetryInitializer(telemetryInitializer);
export { reactPlugin, appInsights };
