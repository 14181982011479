import axios from 'axios';

import authProvider from './authProvider';

/**
 * @param url
 * @param init
 */
const authFetch = async (url: string, init: any = {}) =>
    axios(url, {
        ...init,
        credentials: 'include',
        headers: {
            ...init.headers,
            Authorization: `Bearer ${(await authProvider.getAccessToken()).accessToken}`
        },
        data: init.body ? init.body : init.data
    })
        .then((res) => {
            if (res.status >= 200 && res.status < 300) {
                return res;
            }
            else {
                throw res;
            }
        })
        .catch((err) => {
            throw err;
        });

export default authFetch;
