/**
 * Redux observable epic for initialize the user
 */
import { ofType } from 'redux-observable';
import { concat, of, timer } from 'rxjs';
import { mergeMapTo, switchMap, take } from 'rxjs/operators';

import { loadUser } from 'mahso-common/services/user/slice';

import { initApp as initAppAction } from '~/Modules/app/actions';

import { loadIssues } from '../Issues/issuesSlice';

const initApp = (action$: any) =>
    action$.pipe(
        ofType(initAppAction),
        take(1),
        mergeMapTo(
            concat(
                of(loadUser(), loadIssues(false) /* , , loadVersions(false) */),
                timer(1000).pipe(switchMap(() => of(loadIssues(true) /* , loadVersions(true) */)))
            )
        )
    );
export default initApp;
