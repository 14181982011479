import authFetch from 'mahso-common/services/authorization/authFetch';

import { UserApi as UserApiCreator } from './apiFactory';

let BASE_PATH: string = "";
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
    BASE_PATH = `${process.env.REACT_APP_FACILITY_REVIEW_HIERARCHY_API}${`/mahso/common/v1`.replace(
        /\/+$/,
        ''
    )}`;
}

export const UserApi = new UserApiCreator(undefined, BASE_PATH, authFetch);
