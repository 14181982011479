import { combineReducers } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';

import loadUser from 'mahso-common/services/user/epics/loadUser';
import userReducer from 'mahso-common/services/user/slice';

import addComment from '../Modules/epics/addComment';
import initApp from '../Modules/epics/init';
import loadIssues from '../Modules/epics/loadIssues';
import loadVersions from '../Modules/epics/loadVersions';
import saveIssue from '../Modules/epics/saveIssue';
import saveVersion from '../Modules/epics/saveVersion';
import updateComment from '../Modules/epics/updateComment';
import issuesReducer from '../Modules/Issues/issuesSlice';
import versionsReducer from '../Modules/Version/versionSlice';

export const rootReducer = combineReducers({
    issues: issuesReducer,
    versions: versionsReducer,
    user: userReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const rootEpic = combineEpics(
    initApp,
    loadIssues,
    loadUser,
    loadVersions,
    saveIssue,
    saveVersion,
    addComment,
    updateComment
);
