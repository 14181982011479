import { createSelector } from '@reduxjs/toolkit';
import { values } from 'lodash';
import createCachedSelector from 're-reselect';

import {
    ActionsAvailable,
    Issue,
    Issues,
    IssuesState,
    IssueStatus,
    IssueType,
    JsonData,
    State
} from '../../Utilities/types';

/**
 * Get the Issues slice of the state.
 *
 * @param {object} state - the application redux state
 * @returns {object} the Issues slice of the state
 */
const selectIssuesState = (state: State): IssuesState => state.issues;

/**
 * Get the current issues
 *
 * @param {object} state - the application redux state
 * @returns {object} a map of issue ids to issue objects
 */
export const selectIssuesMap = createSelector(selectIssuesState, (state): Issues => state.items);

export const selectIssues = createSelector(selectIssuesMap, (issuesMap): Issue[] =>
    values(issuesMap)
);

/**
 * Get a specific issue
 *
 * @param {object} state - the application redux state
 * @param {number} id - the id of the issue
 * @returns {object} the requested issue's data
 */
export const selectIssue = createCachedSelector(
    selectIssuesMap,
    (state, id) => id || undefined,
    (issues, id): Issue => issues[id]
)((state, id) => `${id as string || ""}`);

/**
 * Get the loading status of the issues
 * used for the table
 *
 * @param {object} state - the application redux state
 * @returns {string} the loading status of the issues
 */
export const selectIssuesLoadingStatus = createSelector(
    selectIssuesState,
    (state) => state.loadingStatus
);

/**
 * Gets the loading status of the current issue
 * used for the modals
 */
export const selectIssueLoadingStatus = createSelector(
    selectIssue,
    (issueMap) => issueMap?.loadingStatus
);

/**
 * Get the filters for issues
 *
 * @param {object} state - the application redux state
 * @returns {string} the loading status of the issues
 */
export const selectIssuesFilter = createSelector(
    selectIssuesState,
    (state: IssuesState) => state.filters
);

/**
 * Get the loading time for the issues
 *
 * @param {object} state - the application redux state
 * @returns {Date} the loading status of the issues
 */
export const selectIssuesLoadingTime = createSelector(
    selectIssuesState,
    (state) => state.loadingTime
);

/**
 * Get the answer for a certain form
 */
export const selectFormAnswer = createSelector(
    selectIssue,
    (issueMap) => JSON.parse(issueMap.formAnswers.answer).JsonData || {}
);

/**
 * Get the answer id for a certain form
 */
export const selectFormAnswerId = createSelector(
    selectIssue,
    (issueMap): number => issueMap.formAnswers.id
);

/**
 * Get the ui scheme for the forms
 */
export const selectFormUiSchema = createSelector(
    selectIssue,
    (issueMap) => JSON.parse(issueMap.formAnswers.answer).UiSchema || {}
);

/**
 * Get the scheme for the forms
 */
export const selectFormSchema = createSelector(selectIssue, (issueMap) =>
    JSON.parse(issueMap.formAnswers.formVersion.formSchema.schema) || {}
);

/**
 * Return all form answers from every issue as an array of objects.
 */
export const allFormAnswers = createSelector(selectIssues, (collections): JsonData[] =>
    Object.keys(collections).map((key) => JSON.parse(collections[key].formAnswers.answer).JsonData as JsonData)
);

/**
 * Return all statuses from every issue as an array of objects.
 */
export const allStatus = createSelector(selectIssues, (collections): IssueStatus[] =>
    Object.keys(collections).map((key) => collections[key].status as IssueStatus)
);

/**
 * Return all Topics (type of Issue) from every issue as an array of objects.
 */
export const allTopics = createSelector(selectIssues, (collections): IssueType[] =>
    Object.keys(collections).map((key) => collections[key].typeOfIssue as IssueType)
);

/**
 * Return all Action Available from every issue as an array of objects.
 */
export const allActionsAvailable = createSelector(selectIssues, (collections): ActionsAvailable[] =>
    Object.keys(collections).map((key) => collections[key].actionsAvailable as ActionsAvailable)
);
/**
 * Returns the actions available for a specific issue
 */
export const actionsAvailableFromIssue = createSelector(
    selectIssue,
    (issue) => issue.actionsAvailable
);

/**
 * Return all IDs from every issue as an array of objects.
 */
export const allIDs = createSelector(selectIssues, (collections): number[] =>
    Object.keys(collections).map((key) => collections[key].id as number)
);

/**
 * Combines multiple arrays into one to be used with the Issues Table page
 */
export const issueTableData = createSelector(
    allFormAnswers,
    allStatus,
    allTopics,
    allActionsAvailable,
    allIDs,
    (answersCol, statusCol, topicsCol, actionsCol, idCol) =>
        answersCol.map((x, i) => ({
            jsonData: answersCol[i],
            status: statusCol[i],
            topic: topicsCol[i],
            actionsAvailable: actionsCol[i],
            id: idCol[i]
        }))
);
