import { Action, PayloadAction } from '@reduxjs/toolkit';
import epicRequest from 'mahso-common/services/utilities/epicRequest';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { IssueApi } from '../../api';
import { appInsights } from '../../Utilities/AppInsights';
import {
    loadIssues as loadIssuesAction,
    loadIssuesDone,
    loadIssuesError,
    loadIssuesStart,
    setIssues
} from '../Issues/issuesSlice';

const loadIssues = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(loadIssuesAction),
        epicRequest({
            payloadReducer: undefined,
            request: ({ payload }: PayloadAction<boolean>) =>
                from(IssueApi.getissues(undefined, undefined, undefined, payload)).pipe(
                    switchMap((issues) => of(setIssues(issues))),
                    catchError((error) => {
                        appInsights.trackException({ error: new Error(error) });
                        throw error;
                    })
                ),
            actions: {
                start: loadIssuesStart,
                done: loadIssuesDone,
                fail: loadIssuesError
            }
        })
    );
export default loadIssues;
