/**
 * Constants for the MAHSO application.
 */
export const AsyncState = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    EDITING: 'EDITING',
    NONE: 'NONE'
};

export enum IssueState {
    REVIEW = 'Review',
    UNDER_INVESTIGATION = 'Under Investigation',
    REQUIRES_REMEDIATION = 'Requires Remediation',
    SAVE_TO_HIERARCHY = 'Save to Hierarchy',
    CLOSE_ISSUE_MANUALLY = 'Closed - Manually',
    CLOSE_ISSUE_ISSUE_RESOLVED = 'Closed - Issue Resolved'
}
export const COMMON_API_PATH = `${process.env.REACT_APP_FACILITY_REVIEW_HIERARCHY_API
    }${`/mahso/common/v1`.replace(/\/+$/, '')}`;
export const FACILITY_HIERARCHY_API_PATH = `${process.env.REACT_APP_FACILITY_REVIEW_HIERARCHY_API
    }${`/mahso/facilityhierarchyreview/v1`.replace(/\/+$/, '')}`;
export const AOL_API_PATH = `${process.env.REACT_APP_FACILITY_REVIEW_HIERARCHY_API
    }${`/mahso/aol/v1`.replace(/\/+$/, '')}`;
