import React from 'react';

import {
    faComment,
    faEye,
    faSquare,
    faWrench,
    IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { reactPlugin } from '../../Utilities/AppInsights';
import { ActionsAvailable, IssueStatus } from '../../Utilities/types';

type ButtonOptionsProps = {
    id: number;
    actionsAvailable: ActionsAvailable[];
};

type IssueActionButtonProps = {
    id: number;
    status: IssueStatus;
    actionsAvailable: ActionsAvailable[];
};
/**
 * Dynamically add dropdown options for each issue based off its requirements
 * @param props A single issue object
 * @returns Array - An array consisting of multiple HTML elements
 * containing one Dropdown Item in each index
 */
const ButtonOptions = ({ id, actionsAvailable }: ButtonOptionsProps): JSX.Element[] => {
    // Array to hold HTML elements
    let statusOptions: JSX.Element[] = [];

    // Different Icons based off the ending url name
    const objects: { [key: string]: IconDefinition } = {
        review: faEye,
        requiresremediation: faWrench,
        closeissue: faSquare,
        comments: faComment
    };
    if (actionsAvailable) {
        statusOptions = actionsAvailable.map((action) => (
            <Dropdown.Item as={Link} to={`/${id}/${action.nextStatusIfActedOn.name.toLowerCase()}`}>
                <FontAwesomeIcon icon={objects[action.nextStatusIfActedOn.name.toLowerCase()]} />
                {` ${action.name}`}
            </Dropdown.Item>
        ));
    }

    return statusOptions;
};

/**
 *
 * @param param0
 * @returns
 */
const IssueActionButton = ({
    status,
    id,
    actionsAvailable
}: IssueActionButtonProps): JSX.Element => (
    <Dropdown>
        <Dropdown.Toggle className="btn btn-secondary btn-block" id="actionButton">
            {status.name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {/* Pass issue data consisting of an object, 
                then map the array it returns for multiple options */}
            {ButtonOptions({ id, actionsAvailable }).map(
                (SingleButtonOption) => SingleButtonOption
            )}
        </Dropdown.Menu>
    </Dropdown>
);

export default withAITracking(reactPlugin, IssueActionButton, 'IssueActionButton');
