export enum AsyncState {
    PENDING = 'PENDING',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export enum UserRole {
    ADMINISTRATOR = 'ADMINISTRATOR',
    VISN_PLANNER = 'VISN_PLANNER',
    MARKET_USER = 'MARKET_USER',
    CSO_TRIAGE = 'CSO_TRIAGE',
    CONTRACTOR_TRIAGE = 'CONTRACTOR_TRIAGE',
    FACILITY_HIERARCHY_CONTRIBUTOR = 'FACILITY_HIERARCHY_CONTRIBUTOR'
}
