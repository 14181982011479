import './Modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { JSONSchema6 } from 'json-schema';
import _ from 'lodash';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import { useHistory, useParams } from 'react-router-dom';

import { AsyncState } from 'mahso-common/services/constants';
import {
    selectFirstName,
    selectLastName,
    selectUPN,
    selectUsername
} from 'mahso-common/services/user/selectors';

import { reactPlugin } from '../../Utilities/AppInsights';
import { IssueState } from '../../Utilities/constants';
import { CommentUpdate, IssueUpdate, State } from '../../Utilities/types';
import { actionsAvailableFromIssue, selectIssueLoadingStatus } from './issuesSelector';
import { addComment as addCommentAction, saveIssue as saveIssueAction } from './issuesSlice';

interface CloseIssueModalProps {
    issueID: string;
}

const uiSchema = {
    closeIssueDetails: {
        'ui:widget': 'textarea',
        'ui:options': {
            rows: 5
        }
    }
};

const schema: JSONSchema6 = {
    title: '',
    type: 'object',
    required: ['closeIssueDetails'],
    properties: {
        closeIssueDetails: {
            type: 'string',
            title: 'Please provide details on why this issue can now be closed.'
        }
    }
};

/**
 * Set created date to today
 */
const created = new Date();

/**
 * The markup to render to the DOM within this component.
 *
 * @returns JSX markup
 * modal show = dialog open
 */
const CloseIssueModal: React.FC<CloseIssueModalProps> = () => {
    const history = useHistory();
    const { issueID } = useParams<CloseIssueModalProps>();
    const dispatch = useDispatch();
    const [buttonStatus, setButtonStatus] = useState<boolean>(true);
    const [formData, setFormData] = useState<{ closeIssueDetails: string }>();
    const [loadingStatus, setLoading] = useState<AsyncState>();
    const [saveClicked, setSaveClicked] = useState(false);

    // Get current user's UPN
    const currentUserUPN = useSelector(selectUPN);
    // Get current user's FirstName
    const currentUserFirstName = useSelector(selectFirstName);
    // Get current user's LastName
    const currentUserLastName = useSelector(selectLastName);
    // Get current user's Username
    const currentUserUsername = useSelector(selectUsername);
    // Get Issues loading status
    const postStatus = useSelector((state: State) => selectIssueLoadingStatus(state, issueID));
    // Get the available actions
    const actionsAvailable = useSelector((state: State) =>
        actionsAvailableFromIssue(state, issueID)
    );

    useEffect(() => {
        if (saveClicked && postStatus !== undefined) {
            setLoading(postStatus);
        }
    }, [postStatus, saveClicked]);

    // Executed when Close button or "X" button are clicked.
    const handleClose = useCallback(() => {
        history.replace('/');
        history.goForward();
    }, [history]);

    /**
     * Creates the payload for the Issue portion
     *
     * @returns Issue Payload
     */
    const issuePayload = (): IssueUpdate => ({
        id: Number(issueID),
        status: {
            name: IssueState.CLOSE_ISSUE_MANUALLY
        }
    });

    /**
     * Creates the payload for the Comment portion
     *
     * @param event
     * @param event.formData
     * @param event.formData.closeIssueDetails
     * @returns Comment Payload
     */
    const commentPayload = (event: { formData: { closeIssueDetails: string } }): CommentUpdate => ({
        id: Number(issueID),
        comment: {
            comment: event.formData.closeIssueDetails,
            created: `${`0${created.getUTCMonth() + 1}`.slice(
                -2
            )}-${`0${created.getUTCDate()}`.slice(-2)}-${created.getUTCFullYear()}`,
            createdBy: {
                upn: currentUserUPN,
                username: currentUserUsername,
                firstName: currentUserFirstName,
                lastName: currentUserLastName,
                roles: []
            }
        },
        actionId: _.result(
            _.find(actionsAvailable, { name: 'Close Issue' }),
            'nextStatusIfActedOn.id'
        )
    });

    /**
     * Executed when Save button is clicked.
     *
     * @param event
     * @param event.formData
     * @param event.formData.closeIssueDetails
     */
    const handleSave = (event: { formData: { closeIssueDetails: string } }): void => {
        dispatch(saveIssueAction(issuePayload()));
        dispatch(addCommentAction(commentPayload(event)));

        // handleClose();
        setTimeout(() => {
            handleClose();
        }, 5000);
        setSaveClicked(true);
    };

    /**
     * Handle Change in Text Box
     */
    const handleChange = useCallback(
        (form: {
            formData: {
                closeIssueDetails: string;
            };
        }) => {
            if (
                // code only touches objects with hard coded keys
                // eslint-disable-next-line no-prototype-builtins
                form.formData.hasOwnProperty('closeIssueDetails') &&
                form.formData.closeIssueDetails
            ) {
                if (form.formData.closeIssueDetails.length >= 3) {
                    setButtonStatus(false);
                } else {
                    setButtonStatus(true);
                }
            }
            setFormData(form.formData);
        },
        [setButtonStatus, setFormData]
    );
    let content;
    if (loadingStatus === AsyncState.SUCCESS) {
        content = <h4>Issue Closed Successfully</h4>;
    } else if (loadingStatus === AsyncState.PENDING) {
        content = (
            <div>
                <Spinner animation="border" role="status">
                </Spinner>
                <h4>Loading...</h4>
            </div>
        );
    } else if (loadingStatus === AsyncState.ERROR) {
        content = (
            <div>
                <h4 style={{ color: 'red' }}>
                    We experienced an error closing the issue, please contact the support team.
                </h4>
                <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    onSubmit={handleSave}
                    onChange={handleChange}
                    formData={formData}
                >
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>{' '}
                    <Button type="submit" variant="primary" disabled={buttonStatus}>
                        Save
                    </Button>
                </Form>
            </div>
        );
    } else {
        content = (
            <Form
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={handleSave}
                onChange={handleChange}
                formData={formData}
            >
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>{' '}
                <Button type="submit" variant="primary" disabled={buttonStatus}>
                    Save
                </Button>
            </Form>
        );
    }
    return (
        <Modal show onHide={handleClose} centered dialogClassName="closeIssueModal">
            <Modal.Dialog>
                <Modal.Header closeButton>
                    <Modal.Title>Close Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>{content}</Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
};

export default withAITracking(reactPlugin, CloseIssueModal, 'CloseIssueModal');
