import { Action, PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { UserApi } from 'mahso-common/data/api';
import { UserInfo } from 'mahso-common/services/types';
import epicRequest from 'mahso-common/services/utilities/epicRequest';

import {
    loadUser as loadUserAction,
    loadUserDone,
    loadUserError,
    loadUserStart,
    setUser
} from '../slice';

const loadUser = (action$: Observable<Action>): Observable<Action> =>
    action$.pipe(
        ofType(loadUserAction),
        epicRequest({
            payloadReducer: undefined,
            request: ({ payload }: PayloadAction<UserInfo>) =>
                from(UserApi.getUser(payload)).pipe(
                    switchMap((user) => of(setUser(user))),
                    catchError((error) => {
                        throw error;
                    })
                ),
            actions: {
                start: loadUserStart,
                done: loadUserDone,
                fail: loadUserError
            }
        })
    );
export default loadUser;
