export default class RelativeURL extends URL {
    protected base: string | URL | undefined;
    constructor(url: string | URL, base?: string | URL | undefined) {
        try {
            if (base) {
                super(url, base);
                this.base = base;
            } else {
                super(url);
                this.base = url;
            }
        } catch {
            super(url, 'https://localhost');
        }
    }
    public get URL() {
        return this.base ? this.href : this.href.replace(this.origin, '');
    }
}
