import { createSelector } from '@reduxjs/toolkit';

import { UserRole } from 'mahso-common/services/constants';
import { State, UserState } from 'mahso-common/services/types';

/**
 * Get the User slice of the state.
 *
 * @param state - the application redux state
 * @returns the User slice of the state
 */
const selectUserState = (state: State): UserState => state.user;

/**
 * Get the Loading status of the current user
 *
 * @param state - the application redux state
 * @returns loading status of user
 */
export const selectLoadingStatus = createSelector(selectUserState, (user) => user.loadingStatus);

/**
 * Get the userInfo of a given UserState
 *
 * @param state - the AOL redux state
 * @returns the data of a single user
 */
export const selectUserInfo = createSelector(selectUserState, (state) => state.userInfo);

/**
 * Get the upn of the current user
 *
 * @param state - the application redux state
 * @returns upn of user
 */
export const selectUPN = createSelector(selectUserInfo, (userInfo) => userInfo?.upn);

/**
 * Get the username of the current user
 *
 * @param state - the application redux state
 * @returns username of user
 */
export const selectUsername = createSelector(selectUserInfo, (userInfo) => userInfo?.username);

/**
 * Get the firstName of the current user
 *
 * @param state - the application redux state
 * @returns firstName of user
 */
export const selectFirstName = createSelector(selectUserInfo, (userInfo) => userInfo?.firstName);

/**
 * Get the lastName of the current user
 *
 * @param state - the application redux state
 * @returns lastName of user
 */
export const selectLastName = createSelector(selectUserInfo, (userInfo) => userInfo?.lastName);

/**
 * Get the UserRole array of userInfo within the UserState
 *
 * @param state - the AOL redux state
 * @returns the list of roles a user may have
 */
export const selectUserRoles = createSelector(selectUserInfo, (userInfo) => userInfo?.roles || []);

/**
 * Check if the user has the Administrator role
 *
 * @param state - the AOL redux state
 * @returns true or false, true if the user has the administrator role
 */
export const selectIsAdministrator = createSelector(selectUserRoles, (roles) =>
    roles.includes(UserRole.ADMINISTRATOR)
);

/**
 * Check if the user has the Visn Planner role
 *
 * @param state - the AOL redux state
 * @returns true or false, true if the user has the visn planner role
 */
export const selectIsVISNPlanner = createSelector(selectUserRoles, (roles) =>
    roles.includes(UserRole.VISN_PLANNER)
);

/**
 * Check if the user has the Market User role
 *
 * @param state - the AOL redux state
 * @returns true or false, true if the user has the market user role
 */
export const selectIsMarketUser = createSelector(selectUserRoles, (roles) =>
    roles.includes(UserRole.MARKET_USER)
);

/**
 * Check if the user has the CSO Triage role
 *
 * @param state - the AOL redux state
 * @returns true or false, true if the user has the CSO triage role
 */
export const selectIsCSOTriage = createSelector(selectUserRoles, (roles) =>
    roles.includes(UserRole.CSO_TRIAGE)
);

/**
 * Check if the user has the Contractor Triage role
 *
 * @param state - the AOL redux state
 * @returns true or false, true if the user has the contractor triage role
 */
export const selectIsContractorTriage = createSelector(selectUserRoles, (roles) =>
    roles.includes(UserRole.CONTRACTOR_TRIAGE)
);
