import './IssuesTable.css';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { faCheck, faExclamationTriangle, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import moment from 'moment';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import ToolkitProvider, { ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { Route } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';

import { reactPlugin } from '../../Utilities/AppInsights';
import { IssueState } from '../../Utilities/constants';
import CloseIssueModal from './CloseIssueModal';
import CommentModal from './CommentModal';
import IssueActionButton from './IssueActionButton';
import IssueReviewModal from './IssueReviewModal';
import { issueTableData, selectIssuesLoadingStatus } from './issuesSelector';
import IssueTableButtons from './IssueTableButtons';
import RequiresRemediationModal from './RequiresRemediationModal';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        Showing {from} to {to} of {size} Issues
    </span>
);

const options = {
    disablePageTitle: true,
    paginationSize: 3,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
        {
            text: '10',
            value: 10
        }
    ] // A numeric array is also available. the purpose of above example is custom the text
};

const IssuesTable: React.FC = () => {
    // Hooks
    const [currentFilters, setFilters] = useState(undefined);

    const [query, setQuery] = useQueryParams({
        status: StringParam,
        topic: StringParam,
        market: StringParam,
        stationNumber: StringParam,
        officialStationName: StringParam,
        locationDescriptiveName: StringParam,
        parentStationNumber: StringParam,
        parentStation: StringParam
    });
    /**
     * Variable that contains the page content
     */
    let content;
    /**
     * Get all issues to populate data table
     */
    const issueDataTable = useSelector(issueTableData);
    /**
     * Get issues loading status
     */
    const postStatus = useSelector(selectIssuesLoadingStatus);

    // Be able to filter data that has no data
    issueDataTable.forEach((item) => {
        if (item.jsonData.visnObj.market === '') {
            item.jsonData.visnObj.market = ' ';
        }
        if (item.jsonData.stationNumber === '') {
            item.jsonData.stationNumber = ' ';
        }
        if (item.jsonData.officialStationName === '') {
            item.jsonData.officialStationName = ' ';
        }
        if (item.jsonData.locationDescriptiveName === '') {
            item.jsonData.locationDescriptiveName = ' ';
        }
        // Strange issue with parentStation not "existing"
        if (item.jsonData.parentStation === '') {
            item.jsonData.parentStation = ' ';
        }
        return item;
    });

    /**
     * Assign dropdown menu options for all columns
     * @param column The column the user wishes to filter
     * @returns An object with all dropdown menu options for a single column
     */
    const filterOptions = (column: string) => {
        const objectOfOptions = {};
        // For each row/issue in the json
        // Based off what parameter has been put in
        // put all options for a single column into the object

        // For columns without any filter data, need to be handled first to ensure they display at the top
        issueDataTable.forEach((item) => {
            switch (column) {
                case 'market': // Market Column
                    if (item.jsonData.visnObj[column] === ' ') {
                        objectOfOptions[' '] = 'No Data';
                    }
                    break;
                default:
                    // All other columns
                    if (item.jsonData[column] === ' ') {
                        objectOfOptions[' '] = 'No Data';
                    }
            }
        });

        issueDataTable.forEach((item) => {
            switch (column) {
                case 'name': // Status Column
                    objectOfOptions[item.status[column]] = item.status[column];
                    break;
                case 'nameTopic': // Topic Column
                    objectOfOptions[item.topic.name] = item.topic.name;
                    break;
                case 'market': // Market Column
                    if (item.jsonData.visnObj[column] !== ' ') {
                        objectOfOptions[item.jsonData.visnObj[column]] =
                            item.jsonData.visnObj[column];
                    }
                    break;
                default:
                    // All other columns
                    if (item.jsonData[column] !== ' ') {
                        objectOfOptions[item.jsonData[column]] = item.jsonData[column];
                    }
            }
        });
        return objectOfOptions;
    };

    // For topic column status's icon
    const checkStatus = (cell, row) => {
        if (
            row?.status?.name === IssueState.CLOSE_ISSUE_ISSUE_RESOLVED ||
            row?.status?.name === IssueState.CLOSE_ISSUE_MANUALLY
        ) {
            return (
                <div>
                    <FontAwesomeIcon icon={faCheck} color="green" />
                    {` ${(cell as string) || ''}`}
                </div>
            );
        }
        return (
            <div>
                <FontAwesomeIcon icon={faExclamationTriangle} color="orange" />
                {` ${(cell as string) || ''}`}
            </div>
        );
    };

    // Formatted topic title column name
    const columnHeaders = (headerName, components) => (
        <span>
            {headerName.text} <FontAwesomeIcon icon={faFilter} />
            {components.filterElement}
        </span>
    );

    /**
     * Column formats
     * dataField - Name within the JSON to grab from
     * text - Name to be displayed to the user
     * hidden - Will be part of the data and be displayed in downloaded excel
     * but not within the site
     * formatter - How to format each cell
     * filter - Filter options
     * filter.selectFilter - The filter options will be a dropdown select field
     * filter.options - What to filter by
     * filter.defaultValue - Default value when initially loading the page
     */
    const columns: ColumnDescription<any, any>[] = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {
            dataField: 'status.name',
            text: 'Status',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            formatter: (cell, row) => <IssueActionButton {...(row || {})} />,
            filter: selectFilter({
                options: filterOptions('name'),
                defaultValue: query.status
            }),
            headerStyle: () => ({ width: '18%', 'fontWeight': 'normal' })
        },
        {
            dataField: 'topic.name',
            text: 'Topic',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            formatter: (cell, row) => checkStatus(cell, row || {}),
            filter: selectFilter({
                options: filterOptions('nameTopic'),
                defaultValue: query.topic
            }),
            headerStyle: () => ({ width: '24%', 'fontWeight': 'normal' })
        },
        {
            dataField: 'jsonData.visnObj.market',
            text: 'Market',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            filter: selectFilter({
                options: filterOptions('market'),
                defaultValue: query.market
            }),
            headerStyle: () => ({ width: '8%', 'fontWeight': 'normal' })
        },
        {
            dataField: 'jsonData.stationNumber',
            text: 'Station #',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            filter: selectFilter({
                options: filterOptions('stationNumber'),
                defaultValue: query.stationNumber
            }),
            headerStyle: () => ({ width: '8%', 'fontWeight': 'normal' })
        },
        {
            dataField: 'jsonData.officialStationName',
            text: 'Official Station Name',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            filter: selectFilter({
                options: filterOptions('officialStationName'),
                defaultValue: query.officialStationName
            }),
            style: { overflowWrap: 'break-word' },
            headerStyle: () => ({ width: '14%', 'fontWeight': 'normal' })
        },
        {
            dataField: 'jsonData.locationDescriptiveName',
            text: 'Location Descriptive Name',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            filter: selectFilter({
                options: filterOptions('locationDescriptiveName'),
                defaultValue: query.locationDescriptiveName
            }),
            style: { overflowWrap: 'break-word' },
            headerStyle: () => ({ width: '17%', 'fontWeight': 'normal' })
        },
        /* {
            dataField: 'jsonData.latitude',
            text: 'Parent Station #',
            filter: selectFilter({
                options: () => filterOptions('latitude'),
                defaultValue: query.parentStationNumber
            })
        }, */
        {
            dataField: 'jsonData.parentStation',
            text: 'Parent Station Name',
            headerFormatter: (cell, colIndex, components) => columnHeaders(cell, components),
            filter: selectFilter({
                options: filterOptions('parentStation'),
                defaultValue: query.parentStation
            }),
            headerStyle: () => ({ width: '15%', 'fontWeight': 'normal' })
        }
    ];

    /**
     * Function that's invoked after a filter has been set
     * @param newResult
     * @param newFilters New Filters
     */
    function afterFilter(newResult, newFilters) {
        setFilters(newFilters);
    }

    // useEffect will run only after the initial render, and after an update on postStatus
    // This is to avoid the page being reloaded to start the spinning animation.
    // useEffect(() => {
    //     if (postStatus === 'SUCCESS' || postStatus === 'ERROR') {
    //         setLoading(postStatus);
    //     }
    // }, [postStatus]);

    /**
     * Listens to see if a new filter has been applied by setFilters, will update the URL
     * This also makes sure that react does not re-render constantly
     * for it will only re-render when currentFilters has changed
     */
    useEffect(() => {
        if (currentFilters !== undefined) {
            // Set the URL to have the currently selected filter
            // If filter is deselected, set it to undefined to remove from the URL
            setQuery({
                status:
                    currentFilters['status.name'] !== undefined
                        ? currentFilters['status.name'].filterVal
                        : undefined,
                topic:
                    currentFilters['topic.name'] !== undefined
                        ? currentFilters['topic.name'].filterVal
                        : undefined,
                market:
                    currentFilters['jsonData.visnObj.market'] !== undefined
                        ? currentFilters['jsonData.visnObj.market'].filterVal
                        : undefined,
                stationNumber:
                    currentFilters['jsonData.stationNumber'] !== undefined
                        ? currentFilters['jsonData.stationNumber'].filterVal
                        : undefined,
                officialStationName:
                    currentFilters['jsonData.officialStationName'] !== undefined
                        ? currentFilters['jsonData.officialStationName'].filterVal
                        : undefined,
                locationDescriptiveName:
                    currentFilters['jsonData.locationDescriptiveName'] !== undefined
                        ? currentFilters['jsonData.locationDescriptiveName'].filterVal
                        : undefined,
                parentStationNumber:
                    currentFilters['jsonData.parentStationNumber'] !== undefined
                        ? currentFilters['jsonData.parentStationNumber'].filterVal
                        : undefined,
                parentStation:
                    currentFilters['jsonData.parentStation'] !== undefined
                        ? currentFilters['jsonData.parentStation'].filterVal
                        : undefined
            });
        }
    }, [currentFilters, setQuery]);

    if (postStatus === 'PENDING') {
        content = (
            <div>
                <Spinner animation="border" role="status">
                </Spinner>
                <h4>Loading...</h4>
            </div>
        );
    } else if (postStatus === 'SUCCESS') {
        content = (
            <div>
                <Row>
                    <Col sm={8}>
                        <legend>Facility Changes Requiring Review</legend>
                    </Col>
                    <Col sm={4}>
                        <div className="float-right">
                        </div>
                    </Col>
                </Row>
                <div>
                    <BootstrapTable
                        keyField="id"
                        striped
                        hover
                        bordered={false}
                        filter={filterFactory({ afterFilter })}
                        pagination={paginationFactory(options)}
                        data={issueDataTable}
                        columns={columns}
                    />
                </div>
            </div>
        );
    } else if (postStatus === 'ERROR') {
        content = (
            <h1>We experienced an error retrieving the issues, please contact the support team.</h1>
        );
    }

    return (
        <div>
            <Route
                exact
                path="/:issueID/requiresremediation"
                component={RequiresRemediationModal}
            />
            <Route exact path="/:issueID/review" component={IssueReviewModal} />
            <Route exact path="/:issueID/comments" component={CommentModal} />
            <Route exact path="/:issueID/closeissue" component={CloseIssueModal} />
            <div id="content">
                <Container fluid>
                    <Row>
                        <Col sm={12}>{content}</Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default withAITracking(reactPlugin, IssuesTable, 'IssuesTable');
