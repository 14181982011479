import { AsyncState } from 'mahso-common/services/constants';
import { UserInfo, UserState } from 'mahso-common/services/types';

import { IssueState } from './constants';

export type IssueUpdate = {
    id: number;
    status?: {
        name: IssueState;
    };
    formAnswers?: FormAnswerUpdate;
};

export type CommentUpdate = {
    id: number;
    comment: Comment;
    actionId: number;
};

export type FormAnswerUpdate = {
    id: number;
    answer?: string;
};

export type IssuesState = {
    loadingStatus: AsyncState;
    loadingTime?: Date;
    filters: Record<string, unknown>;
    items: Issues;
    loadingError?: string;
};

export type Issues = {
    [index: string]: Issue;
};

export type Issue = {
    id: number;
    loadingStatus: AsyncState;
    stationNumber?: string;
    issueType?: IssueType;
    status?: IssueStatus;
    actionsAvailable?: ActionsAvailable[];
    lastModifiedBy?: UserInfo;
    lastModified?: Date;
    formAnswers?: FormAnswer;
    comments?: Comment[];
};

export type JsonData = {
    stationNumber: string;
    parentStation: string;
    officialStationName: string;
    locationDescriptiveName: string;
    classification: string;
    complexity: string;
    visnObj: {
        submarket: string;
        sector: string;
        market: string;
        visn: string;
    };
    stateObj: {
        state: string;
        county: string;
    };
    congressionalDistrict: string;
    latitude: string;
    longitude: string;
    isColocated: string;
};

export type FormAnswer = {
    answer?: string;
    formVersion: FormVersion;
    id: number;
};

export type FormVersion = {
    form?: { [key: string]: unknown };
    formSchema: FormSchema;
    id: number;
};

export type FormSchema = {
    id: number;
    schema: string;
};

export type IssueType = {
    id: number;
    name?: string;
    description?: string;
};

export type NextStatusIfActedOn = {
    id: number;
    name: string;
    description: string;
};
export type ActionsAvailable = {
    id: number;
    name: string;
    nextStatusIfActedOn: NextStatusIfActedOn;
};

export type IssueStatus = {
    id?: number;
    name: string;
    description?: string;
};

export type Comment = {
    id?: number;
    comment: string;
    created?: string;
    createdBy?: UserInfo;
};

export type VersionsState = {
    loadingStatus: string;
    loadingTime?: Date;
    filters: Record<string, unknown>;
    items: { [index: string]: Record<string, unknown> };
};

export type State = {
    issues: IssuesState;
    version: VersionsState;
    user: UserState;
};

export const initialIssuesState: IssuesState = {
    loadingStatus: undefined,
    loadingTime: undefined,
    filters: {},
    items: {}
};

export const initialVersionState: VersionsState = {
    loadingStatus: '',
    loadingTime: undefined,
    filters: {},
    items: {}
};

export type IssueObject = {
    [key: string]: any;
};
