import './UnauthorizedUserModal.css';

import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import logo from '../../images/cl-va-logo-modal.png';
import closeImage from '../../images/cl-lightweight-modal-close-button.png';

const UnauthorizedUserAboutUsModal = (): JSX.Element => {
    const history = useHistory();

    const handleClose = useCallback(() => {
        history.replace('/');
        history.goForward();
    }, [history]);

    return (
        <Modal show onHide={handleClose}>
            <Modal.Header className="modal-header-va-blue">
                <Modal.Title>
                    <img src={logo} alt="VA Logo Modal" />
                </Modal.Title>
                <button
                    id="about-modal-close-btn"
                    type="button"
                    aria-label="Close Dialog"
                    className="close"
                    onClick={handleClose}
                >
                    <img src={closeImage} alt="Close Dialog" />
                </button>
            </Modal.Header>
            <Modal.Body>
                <p>
                    VA Single Sign-On Internal (SSOi) is the authentication solution for internal
                    facing (Department of Veterans Affairs (VA) employee, contractor, and volunteer)
                    applications. SSOi allows a user to log in once and gain access to all
                    integrated systems without being prompted to login again to each of them.
                </p>
                <p>
                    SSOi eliminates the need for individual application logon/passwords, reduces
                    keystrokes and clicks, and ensures that only those users with appropriate
                    credentials are allowed access to VA systems.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={handleClose}>
                    Back
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default UnauthorizedUserAboutUsModal;
